import React from 'react';

class ViewMore extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
    this.click = this.click.bind(this);
  }

  click() {
    this.setState({ show: !this.state.show });
  }
  componentToString(txt) {
    var text = '';

    for (let i = 0; i < this.props.ln; i++) {
      if (typeof txt[i] === 'string') {
        text += txt[i];
      } else {
        const element = document.createElement('span');
        element.classList.add('text-purple');
        element.classList.add('font-bold');
        element.innerHTML = txt[i]?.props.children;
        // text += element
      }
    }
    return text;
  }

  render() {
    return (
      <>
        {this.state.show ? (
          <span>{this.props.children}</span>
        ) : (
          <>
            The name WeProdigi is the combination of <span className="text-purple font-bold">We</span> standing for our unity as a huge in–house{' '}
            <span className="text-purple font-bold">Pro</span>fessional team of 30+ certified digital specialists offering you a wide variety of high
            quality <span className="text-purple font-bold">Digi</span>tal services, specifically Digital Marketing and Video Production services of
            any type and style with 100% customized approach.
          </>
        )}{' '}
        <span onClick={this.click} className="text-purple cursor-pointer underline-anim">
          {' '}
          <span>{this.state.show ? 'view less' : 'view more'}</span>
          <svg viewBox="0 0 13 20">
            <polyline points="0.5 19.5 3 19.5 12.5 10 3 0.5" />
          </svg>
        </span>
      </>
    );

    //return (this.state.show ? <>{this.props.full} : <>{this.props.short}<span onClick={this.click} className="text-purple cursor-pointer underline-anim"><span>view more</span><svg viewBox="0 0 13 20"><polyline points="0.5 19.5 3 19.5 12.5 10 3 0.5" /></svg></span></>);
  }
}

export default ViewMore;
