import Head from 'next/head';
import React, { useEffect, useState, Suspense } from 'react';
import dynamic from 'next/dynamic';

const BringResults = dynamic(() => import('../components/HomePage/BringResults'));
import ViewMorePPC from '../components/ViewMorePPC';
import Slider from 'react-slick';
import Offer from '../components/newOfferModal';
import GetProposal from '../components/proposal/ppc/GetProposal';

const SMMPricing = dynamic(() => import('../components/smm/SMMPriceing'));
const Pricing = dynamic(() => import('../components/video-production/Pricing'));

import 'slick-carousel/slick/slick.css';
import ViewMoreWithTags from '../components/ViewMoreWithTags';

const HomePage = props => {
  const [tab, setTab] = useState(0);
  const [showOfferModal, setShowModal] = useState(false);
  const [showOfferModalWeb, setShowOfferModalWeb] = useState(false);
  const [showOfferModalDesign, setShowOfferModalDesign] = useState(false);
  const [showModalPPC, setShowModalPPC] = useState(false);
  const [showModalSMM, setShowModalSMM] = useState(false);
  const [showPricingModal, setShowPricingModal] = useState(false);
  const hideOfferModal = () => {
    if (document.getElementById('form-offer')) document.getElementById('form-offer').reset();
    setShowModal(false);
    document.body.style.overflow = 'auto';
    document.documentElement.style.overflow = 'auto';
  };
  const hideOfferModalWeb = () => {
    setShowOfferModalWeb(false);
    document.body.style.overflow = 'auto';
    document.body.style.overflowX = 'hidden';
    document.documentElement.style.overflow = 'auto';
  };
  const hideOfferModalDesign = () => {
    setShowOfferModalDesign(false);
    document.body.style.overflow = 'auto';
    document.body.style.overflowX = 'hidden';
    document.documentElement.style.overflow = 'auto';
  };
  const hideOfferModaSMM = () => {
    if (document.getElementById('offer-form-smm')) document.getElementById('offer-form-smm').reset();
    document.body.style.overflow = 'auto';
    document.documentElement.style.overflow = 'auto';
    setShowModalSMM(false);
    document.body.style.overflowX = 'hidden';
  };
  const setHidden = () => {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';
  };
  const hideOfferModaPricing = () => {
    setShowPricingModal(false);
    if (document.getElementById('offer-form-smm')) document.getElementById('offer-form-smm').reset();
    document.body.style.overflow = 'auto';
    document.body.style.overflowX = 'hidden';
    document.documentElement.style.overflow = 'auto';
  };
  const hideOfferModalPPC = () => {
    setShowModalPPC(false);
    if (document.getElementById('form-ppc')) document.getElementById('form-ppc').reset();
    document.body.style.overflow = 'auto';
    document.body.style.overflowX = 'hidden';
    document.documentElement.style.overflow = 'auto';
  };

  useEffect(() => {
    setTab(window.innerWidth < 768 ? 0 : 1);
  }, [props.width]);
  useEffect(() => {
    if (window.innerWidth < 768) {
      switch (tab) {
        case 1:
          document.querySelector('#paid-ads-tab').scrollIntoView({
            behavior: 'smooth',
          });
          break;
        case 2:
          document.querySelector('#smm-tab').scrollIntoView({
            behavior: 'smooth',
          });
          break;
        case 3:
          document.querySelector('#video-tab').scrollIntoView({
            behavior: 'smooth',
          });
          break;
        case 4:
          document.querySelector('#web-tab').scrollIntoView({
            behavior: 'smooth',
          });
          break;
        case 5:
          document.querySelector('#design-tab').scrollIntoView({
            behavior: 'smooth',
          });
        default:
          console.log('Sorry, we are out of');
      }
    }
  }, [tab]);
  const settings2 = {
    infinite: true,
    speed: 600,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    swipe: false,
    waitForAnimate: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          arrows: false,
          swipe: true,
          variableWidth: true,
        },
      },
    ],
  };
  const renderLoader = () => <p>Loading</p>;

  return (
    <>
      <Head>
        <title>Result-Guaranteed Digital Marketing Services - WeProdigi</title>
        <meta
          name="description"
          content="We are a digital marketing and production company specializing in PPC services, SMM, Web Development, Games, and Video production services."
        />
        <meta httpEquiv="ScreenOrientation" content="autoRotate:disabled" />
        <meta property="og:url" content="https://weprodigi.com/" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Result-Guaranteed Digital Marketing Services - WeProdigi" />
        <meta
          property="og:description"
          content="We are a digital marketing and production company specializing in PPC services, SMM, Web Development, Games, and Video production services."
        />
        <meta property="og:image" content="/uploads/images/home-page/meta.png" />
        <script
          type="application/ld+json"
          className="organization-schema"
          dangerouslySetInnerHTML={{
            __html: `
                {
                
        "@context": "https://schema.org",
        "@type": "Organization",
        "@id": "https://weprodigi.com#Organization",
        "address": "27 Arabkir stret 14h, Yerevan 0051, Armenia",
        "award": [],
        "description": "Prodigi is a digital marketing and production company specializing in PPC services, SMM, Web Development, Games, and Video production services.",
        "alternateName": "Prodigi",
        "email": "info@weprodigi.com",
        "foundingDate": "2017-09-28",
        "image": "https://weprodigi.com/uploads/images/home-page/our_services.jpg",
        "knowsLanguage": [
        "hy",
        "en",
        "ru",
        "fr"
    ],
        "legalName": "Prodigi LLC",
        "logo": "https://weprodigi.com/images/logovertical.svg",
        "name": "Prodigi Marketing Agency",
        "sameAs": [
        "https://www.facebook.com/weprodigi/",
        "https://www.instagram.com/weprodigi_agency/",
        "https://www.linkedin.com/company/pro.digi/",
        "https://www.youtube.com/channel/UC6mkepDFKswxQWS76hgOMBg",
        "https://twitter.com/WeProdigi",
        "https://goo.gl/maps/rfbWAUxxzA2Kb4am8"
    ],
        "slogan": "Digital Marketing Agency That Brings Results",
        "telephone": [
        "+37412202040",
        "+37444755855",
        "+14378860987",
        "+442045770272",
        "+61288800313",
        "+19295909255"
    ],
        "url": "https://weprodigi.com/",
        "knowsAbout": [
        "https://en.wikipedia.org/wiki/Web_design",
        "https://en.wikipedia.org/wiki/Digital_marketing",
        "https://en.wikipedia.org/wiki/Graphic_design",
        "https://en.wikipedia.org/wiki/Video_clip#Advertising",
        "https://en.wikipedia.org/wiki/Social_media_marketing",
        "https://en.wikipedia.org/wiki/Search_engine_optimization",
        "https://en.wikipedia.org/wiki/Online_advertising"
    ],
        "taxID": "01278077",
        "founder": [
        {
            "@id": "https://weprodigi.com#BorisSahakyan"
        },
        {
            "@id": "https://weprodigi.com#AidaVopyan"
        },
        {
            "@id": "https://weprodigi.com#ArsenSultanyan"
        }
    ],
        "numberOfEmployees": {
        "@id": "https://weprodigi.com#NumberOfEmployees"
    },
        "aggregateRating": {
        "@id": "https://weprodigi.com#AggRating"
    }
    }
        `,
          }}
        />

        <script
          type="application/ld+json"
          className="organization-schema"
          dangerouslySetInnerHTML={{
            __html: `
               {"@context": "https://schema.org",
        "@type": "Person",
        "@id": "https://weprodigi.com#BorisSahakyan",
        "colleague": "https://en.wikipedia.org/wiki/Yerevan_State_University",
        "gender": "male",
        "image": "https://weprodigi.com/images/team/boris2.png",
        "jobTitle": "Co-founder",
        "knowsLanguage": [
        "hy",
        "en",
        "ru"
    ],
        "name": "Boris Sahakyan",
        "sameAs": [
        "https://www.linkedin.com/in/boris-sahakyan-3243364b/",
        "https://www.facebook.com/BorisSahakyan"
    ]
    }
        `,
          }}
        />
        <script
          type="application/ld+json"
          className="organization-schema"
          dangerouslySetInnerHTML={{
            __html: `
               {
        "@context": "https://schema.org",
        "@type": "Person",
        "@id": "https://weprodigi.com#ArsenSultanyan",
        "gender": "male",
        "image": "https://weprodigi.com/images/team/arsen2.png",
        "birthDate": "1981-12-05",
        "colleague": "https://en.wikipedia.org/wiki/Armenian_State_University_of_Economics",
        "jobTitle": "Co-founder",
        "knowsLanguage": [
        "hy",
        "en",
        "ru"
    ],
        "name": "Arsen Sultanyan",
        "sameAs": [
        "https://www.facebook.com/arsen.sultanyan",
        "https://www.linkedin.com/in/arsensultanyan/"
    ]
    }
        `,
          }}
        />

        <script
          type="application/ld+json"
          className="organization-schema"
          dangerouslySetInnerHTML={{
            __html: `
               {
        "@context": "https://schema.org",
        "@type": "AggregateRating",
        "@id": "https://weprodigi.com#AggRating",
        "bestRating": "5",
        "ratingCount": "23",
        "ratingValue": "5",
        "url": "https://goo.gl/maps/rfbWAUxxzA2Kb4am8",
        "worstRating": "1",
        "name": "Google Map AggRating"
    }
        `,
          }}
        />
      </Head>

      <style jsx global>{`
        .icon-hover {
          cursor: pointer;
          filter: brightness(0) invert(1);
        }
        .icon-hover:hover {
          transform: rotate(360deg);
          transition: all 1.5s;
        }
        .icon-hover:not(:hover) {
          transform: rotate(-360deg);
          transition: all 1.5s;
        }
        .head-video:after {
          content: '';
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.45);
          z-index: 1;
        }
        #stars,
        #stars1,
        #stars2 {
          position: absolute;
        }

        #stars {
          content: 'e';
          background: transparent;
          box-shadow:
            1744px 122px #fff,
            134px 1321px #fff,
            92px 859px #fff,
            235px 1045px #fff,
            366px 912px #fff,
            491px 942px #fff,
            14px 1831px #fff,
            582px 476px #fff,
            588px 1230px #fff,
            1520px 1343px #fff,
            1671px 203px #fff,
            550px 341px #fff,
            1491px 549px #fff,
            558px 161px #fff,
            896px 1823px #fff,
            999px 1463px #fff,
            1557px 636px #fff,
            1754px 1307px #fff,
            1682px 1494px #fff,
            703px 1707px #fff,
            1945px 1847px #fff,
            1151px 1320px #fff,
            980px 144px #fff,
            478px 948px #fff,
            109px 1762px #fff,
            558px 255px #fff,
            719px 1820px #fff,
            588px 748px #fff,
            1899px 174px #fff,
            841px 1771px #fff,
            571px 1588px #fff,
            1155px 353px #fff,
            1879px 1220px #fff,
            1782px 870px #fff,
            407px 1238px #fff,
            1141px 63px #fff,
            1147px 1097px #fff,
            1406px 159px #fff,
            637px 1215px #fff,
            694px 1114px #fff,
            1536px 727px #fff,
            1708px 1512px #fff,
            1147px 880px #fff,
            684px 988px #fff,
            140px 851px #fff,
            1565px 1788px #fff,
            1573px 889px #fff,
            1172px 628px #fff,
            1474px 1978px #fff,
            435px 447px #fff,
            185px 1808px #fff,
            620px 1560px #fff,
            1387px 1196px #fff,
            138px 1854px #fff,
            137px 1499px #fff,
            1721px 1132px #fff,
            10px 32px #fff,
            1931px 1990px #fff,
            209px 91px #fff,
            1876px 1795px #fff,
            1130px 1551px #fff,
            284px 1848px #fff,
            389px 1603px #fff,
            612px 1121px #fff,
            825px 1926px #fff,
            161px 344px #fff,
            1729px 333px #fff,
            1037px 37px #fff,
            844px 1085px #fff,
            1179px 554px #fff,
            50px 478px #fff,
            1864px 704px #fff,
            233px 723px #fff,
            1202px 445px #fff,
            882px 189px #fff,
            362px 735px #fff,
            924px 411px #fff,
            902px 209px #fff,
            104px 185px #fff,
            1599px 1852px #fff,
            1974px 944px #fff,
            438px 1164px #fff,
            401px 1533px #fff,
            191px 1429px #fff,
            251px 1034px #fff,
            1807px 1412px #fff,
            72px 23px #fff,
            1752px 1146px #fff,
            261px 1481px #fff,
            548px 33px #fff,
            710px 1204px #fff,
            355px 1697px #fff,
            581px 100px #fff,
            318px 1146px #fff,
            929px 79px #fff,
            999px 347px #fff,
            155px 292px #fff,
            271px 677px #fff,
            920px 1596px #fff,
            1736px 184px #fff,
            1022px 1790px #fff,
            1465px 1762px #fff,
            820px 526px #fff,
            175px 37px #fff,
            440px 746px #fff,
            681px 1879px #fff,
            690px 1135px #fff,
            1960px 1453px #fff,
            422px 856px #fff,
            1217px 1232px #fff,
            1015px 1695px #fff,
            1933px 492px #fff,
            272px 448px #fff,
            1578px 1487px #fff,
            437px 874px #fff,
            947px 838px #fff,
            1339px 867px #fff,
            1484px 773px #fff,
            764px 66px #fff,
            418px 707px #fff,
            192px 1909px #fff,
            1629px 215px #fff,
            171px 260px #fff,
            1180px 220px #fff,
            488px 857px #fff,
            593px 493px #fff,
            1794px 886px #fff,
            1673px 1977px #fff,
            905px 1193px #fff,
            1372px 1843px #fff,
            1605px 908px #fff,
            659px 181px #fff,
            700px 1758px #fff,
            812px 1200px #fff,
            1800px 1440px #fff,
            1858px 212px #fff,
            628px 1026px #fff,
            1825px 1556px #fff,
            1641px 1750px #fff,
            1195px 1086px #fff,
            1465px 558px #fff,
            1634px 436px #fff,
            1354px 1831px #fff,
            1212px 1485px #fff,
            1491px 994px #fff,
            604px 1279px #fff,
            413px 1131px #fff,
            1677px 1086px #fff,
            841px 47px #fff,
            146px 489px #fff,
            117px 1195px #fff,
            67px 47px #fff,
            883px 258px #fff,
            1801px 1294px #fff,
            594px 1870px #fff,
            649px 531px #fff,
            721px 132px #fff,
            975px 1616px #fff,
            716px 94px #fff,
            295px 791px #fff,
            174px 1814px #fff,
            1130px 298px #fff,
            1747px 1835px #fff,
            605px 1521px #fff,
            78px 743px #fff,
            1685px 311px #fff,
            804px 341px #fff,
            1440px 852px #fff,
            1607px 1692px #fff,
            698px 1112px #fff,
            1153px 1608px #fff,
            539px 999px #fff,
            262px 353px #fff,
            457px 1246px #fff,
            1858px 998px #fff,
            364px 431px #fff,
            1907px 912px #fff,
            541px 916px #fff,
            168px 1384px #fff,
            357px 1321px #fff,
            1859px 1866px #fff,
            1001px 909px #fff,
            842px 1663px #fff,
            369px 1176px #fff,
            932px 1372px #fff,
            1606px 732px #fff,
            1844px 857px #fff,
            974px 1588px #fff,
            804px 1139px #fff,
            65px 1213px #fff,
            1066px 863px #fff,
            1991px 1734px #fff,
            300px 738px #fff,
            1260px 1141px #fff,
            83px 260px #fff,
            1219px 167px #fff,
            613px 990px #fff,
            873px 81px #fff,
            362px 235px #fff,
            373px 372px #fff,
            80px 247px #fff,
            902px 1141px #fff,
            294px 464px #fff,
            766px 1925px #fff,
            1151px 1305px #fff,
            1250px 1593px #fff,
            1289px 119px #fff,
            1525px 1505px #fff,
            234px 1306px #fff,
            571px 858px #fff,
            571px 996px #fff,
            766px 1342px #fff,
            1371px 716px #fff,
            711px 1939px #fff,
            904px 1797px #fff,
            424px 1710px #fff,
            762px 1614px #fff,
            1389px 1290px #fff,
            905px 689px #fff,
            352px 38px #fff,
            2000px 1317px #fff,
            597px 864px #fff,
            824px 711px #fff,
            966px 1060px #fff,
            958px 992px #fff,
            1121px 324px #fff,
            1624px 688px #fff,
            1737px 702px #fff,
            1566px 1344px #fff,
            697px 368px #fff,
            291px 1126px #fff,
            1732px 50px #fff,
            712px 1941px #fff,
            1257px 372px #fff,
            317px 1462px #fff,
            222px 309px #fff,
            1139px 647px #fff,
            170px 878px #fff,
            1272px 1511px #fff,
            1009px 1912px #fff,
            1875px 1105px #fff,
            1968px 1370px #fff,
            1193px 687px #fff,
            1498px 611px #fff,
            247px 1993px #fff,
            1686px 1611px #fff,
            937px 340px #fff,
            114px 1423px #fff,
            496px 1191px #fff,
            110px 1284px #fff,
            876px 687px #fff,
            1173px 214px #fff,
            210px 628px #fff,
            302px 1173px #fff,
            1318px 939px #fff,
            1665px 1310px #fff,
            66px 338px #fff,
            1355px 615px #fff,
            1139px 1672px #fff,
            91px 1000px #fff,
            112px 422px #fff,
            89px 503px #fff,
            97px 1599px #fff,
            1031px 1955px #fff,
            1755px 1532px #fff,
            1764px 1930px #fff,
            1822px 1751px #fff,
            133px 401px #fff,
            718px 85px #fff,
            1797px 78px #fff,
            520px 1725px #fff,
            226px 406px #fff,
            1561px 1574px #fff,
            340px 1602px #fff,
            718px 976px #fff,
            1823px 570px #fff,
            1298px 484px #fff,
            1023px 1903px #fff,
            1266px 1334px #fff,
            763px 877px #fff,
            223px 631px #fff,
            1761px 1293px #fff,
            878px 1px #fff,
            973px 696px #fff,
            975px 1841px #fff,
            1276px 302px #fff,
            1399px 1324px #fff,
            1706px 872px #fff,
            341px 1960px #fff,
            1384px 1655px #fff,
            1897px 767px #fff,
            68px 405px #fff,
            705px 310px #fff,
            194px 103px #fff,
            1432px 368px #fff,
            1262px 1682px #fff,
            1933px 1526px #fff,
            263px 655px #fff,
            1031px 1031px #fff,
            1557px 788px #fff,
            1558px 1360px #fff,
            812px 421px #fff,
            1951px 734px #fff,
            1567px 498px #fff,
            1832px 1229px #fff,
            913px 164px #fff,
            1907px 1273px #fff,
            533px 749px #fff,
            1235px 951px #fff,
            645px 1959px #fff,
            92px 1570px #fff,
            1169px 1434px #fff,
            118px 1223px #fff,
            583px 957px #fff,
            1245px 316px #fff,
            527px 863px #fff,
            372px 1007px #fff,
            244px 1251px #fff,
            314px 494px #fff,
            1842px 1463px #fff,
            1315px 730px #fff,
            1655px 1366px #fff,
            482px 979px #fff,
            340px 1808px #fff,
            256px 578px #fff,
            858px 1792px #fff,
            1965px 403px #fff,
            454px 253px #fff,
            1664px 698px #fff,
            1974px 750px #fff,
            262px 1801px #fff,
            1652px 1076px #fff,
            992px 711px #fff,
            1792px 1323px #fff,
            700px 1748px #fff,
            855px 966px #fff,
            1833px 1747px #fff,
            402px 1272px #fff,
            1391px 1039px #fff,
            1395px 1538px #fff,
            397px 1972px #fff,
            744px 1403px #fff,
            1784px 1046px #fff,
            111px 198px #fff,
            1827px 473px #fff,
            1347px 1891px #fff,
            1238px 1081px #fff,
            189px 908px #fff,
            1513px 542px #fff,
            673px 981px #fff,
            720px 1184px #fff,
            373px 792px #fff,
            1470px 1631px #fff,
            16px 822px #fff,
            1935px 829px #fff,
            364px 613px #fff,
            223px 899px #fff,
            1233px 874px #fff,
            1441px 1834px #fff,
            719px 894px #fff,
            76px 1610px #fff,
            296px 685px #fff,
            572px 1637px #fff,
            349px 114px #fff,
            1670px 1802px #fff,
            979px 928px #fff,
            1589px 574px #fff,
            756px 194px #fff,
            616px 507px #fff,
            271px 186px #fff,
            198px 360px #fff,
            1916px 418px #fff,
            721px 667px #fff,
            1937px 1908px #fff,
            17px 556px #fff,
            345px 443px #fff,
            718px 760px #fff,
            1922px 310px #fff,
            1185px 887px #fff,
            1745px 1848px #fff,
            909px 847px #fff,
            1170px 134px #fff,
            1773px 1133px #fff,
            1099px 233px #fff,
            1122px 1524px #fff,
            866px 907px #fff,
            398px 213px #fff,
            1287px 886px #fff,
            1194px 149px #fff,
            1876px 1667px #fff,
            332px 1658px #fff,
            146px 1253px #fff,
            958px 211px #fff,
            643px 47px #fff,
            1213px 1339px #fff,
            1637px 1939px #fff,
            1601px 355px #fff,
            256px 1982px #fff,
            989px 1609px #fff,
            1179px 701px #fff,
            685px 1463px #fff,
            1910px 465px #fff,
            764px 1930px #fff,
            1856px 433px #fff,
            720px 367px #fff,
            1821px 416px #fff,
            48px 1114px #fff,
            716px 489px #fff,
            174px 1972px #fff,
            115px 1373px #fff,
            1487px 141px #fff,
            1919px 1362px #fff,
            1662px 859px #fff,
            1168px 490px #fff,
            706px 1820px #fff,
            588px 318px #fff,
            107px 778px #fff,
            621px 1667px #fff,
            1331px 1996px #fff,
            1404px 1154px #fff,
            860px 1019px #fff,
            1385px 1883px #fff,
            1066px 1951px #fff,
            752px 444px #fff,
            955px 1031px #fff,
            1256px 1899px #fff,
            961px 1455px #fff,
            1101px 84px #fff,
            890px 335px #fff,
            798px 40px #fff,
            138px 54px #fff,
            1934px 252px #fff,
            429px 324px #fff,
            1645px 1522px #fff,
            218px 737px #fff,
            1625px 531px #fff,
            605px 1290px #fff,
            1867px 648px #fff,
            82px 1137px #fff,
            231px 1423px #fff,
            471px 944px #fff,
            1363px 1752px #fff,
            482px 1364px #fff,
            1305px 627px #fff,
            1065px 891px #fff,
            217px 1841px #fff,
            1843px 1501px #fff,
            1681px 734px #fff,
            185px 856px #fff,
            609px 1094px #fff,
            49px 1657px #fff,
            783px 594px #fff,
            1018px 1123px #fff,
            644px 1149px #fff,
            739px 1262px #fff,
            1439px 1187px #fff,
            1789px 1507px #fff,
            941px 791px #fff,
            1319px 1308px #fff,
            498px 626px #fff,
            1594px 858px #fff,
            783px 71px #fff,
            576px 1976px #fff,
            1625px 696px #fff,
            1821px 352px #fff,
            1039px 885px #fff,
            208px 430px #fff,
            124px 198px #fff,
            1964px 686px #fff,
            1440px 1952px #fff,
            231px 1435px #fff,
            1846px 853px #fff,
            1174px 536px #fff,
            1228px 310px #fff,
            1380px 699px #fff,
            1116px 169px #fff,
            1416px 1007px #fff,
            178px 192px #fff,
            1302px 1903px #fff,
            1892px 154px #fff,
            962px 1032px #fff,
            1009px 1916px #fff,
            1549px 1206px #fff,
            899px 968px #fff,
            902px 543px #fff,
            1516px 542px #fff,
            965px 527px #fff,
            1594px 696px #fff,
            856px 1719px #fff,
            1522px 248px #fff,
            1795px 283px #fff,
            1144px 946px #fff,
            1365px 199px #fff,
            359px 1347px #fff,
            1506px 263px #fff,
            433px 1793px #fff,
            201px 247px #fff,
            942px 838px #fff,
            1122px 1065px #fff,
            1997px 108px #fff,
            306px 989px #fff,
            641px 1713px #fff,
            1072px 959px #fff,
            1874px 1079px #fff,
            1938px 269px #fff,
            891px 389px #fff,
            22px 574px #fff,
            727px 474px #fff,
            1671px 1649px #fff,
            98px 400px #fff,
            641px 798px #fff,
            1218px 1051px #fff,
            907px 1485px #fff,
            1002px 1549px #fff,
            1932px 1448px #fff,
            1132px 573px #fff,
            1222px 1147px #fff,
            1009px 451px #fff,
            698px 1940px #fff,
            97px 1123px #fff,
            904px 751px #fff,
            1904px 593px #fff,
            1451px 1658px #fff,
            191px 1606px #fff,
            1346px 482px #fff,
            1935px 1086px #fff,
            1052px 224px #fff,
            526px 1810px #fff,
            1607px 606px #fff,
            427px 1830px #fff,
            1546px 407px #fff,
            1771px 208px #fff,
            1714px 181px #fff,
            1332px 943px #fff,
            1663px 1094px #fff,
            1861px 1747px #fff,
            1429px 1217px #fff,
            1733px 1915px #fff,
            925px 1179px #fff,
            735px 781px #fff,
            1775px 1989px #fff,
            1770px 476px #fff,
            844px 400px #fff,
            1793px 1517px #fff,
            544px 281px #fff,
            725px 344px #fff,
            1274px 764px #fff,
            1845px 339px #fff,
            582px 1745px #fff,
            351px 1287px #fff,
            207px 749px #fff,
            1063px 1411px #fff,
            1246px 342px #fff,
            1538px 166px #fff,
            770px 602px #fff,
            1214px 1971px #fff,
            113px 294px #fff,
            1157px 101px #fff,
            1657px 517px #fff,
            637px 1693px #fff,
            961px 1649px #fff,
            1754px 1572px #fff,
            1885px 659px #fff,
            238px 34px #fff,
            413px 1027px #fff,
            1709px 1556px #fff,
            460px 878px #fff,
            1597px 608px #fff,
            544px 434px #fff,
            1258px 477px #fff,
            565px 1863px #fff,
            426px 322px #fff,
            1917px 850px #fff,
            296px 144px #fff,
            643px 1896px #fff,
            511px 116px #fff,
            359px 847px #fff,
            702px 1133px #fff,
            857px 1371px #fff,
            559px 1758px #fff,
            1615px 1223px #fff,
            1927px 1736px #fff,
            704px 941px #fff,
            1932px 942px #fff,
            1462px 335px #fff,
            154px 1915px #fff,
            1387px 918px #fff,
            1512px 1041px #fff,
            1094px 1334px #fff,
            1678px 865px #fff,
            1960px 431px #fff,
            666px 1258px #fff,
            422px 1215px #fff,
            345px 802px #fff,
            1207px 647px #fff,
            1701px 374px #fff,
            1440px 1925px #fff,
            1536px 1163px #fff,
            905px 155px #fff,
            843px 1036px #fff,
            660px 823px #fff,
            1329px 661px #fff,
            1867px 899px #fff,
            1022px 514px #fff,
            1062px 1570px #fff,
            888px 1786px #fff,
            699px 1738px #fff,
            1831px 1712px #fff,
            434px 18px #fff,
            227px 205px #fff,
            520px 1279px #fff,
            1329px 764px #fff,
            1824px 47px #fff,
            112px 266px #fff,
            830px 1140px #fff,
            478px 637px #fff,
            634px 850px #fff,
            711px 873px #fff,
            987px 249px #fff,
            1454px 909px #fff,
            699px 922px #fff,
            1458px 1142px #fff,
            701px 1893px #fff,
            134px 384px #fff,
            904px 354px #fff,
            1913px 1229px #fff,
            1606px 1336px #fff,
            976px 1776px #fff,
            1726px 605px #fff,
            1244px 590px #fff,
            1351px 526px #fff,
            1997px 130px #fff,
            1137px 1134px #fff,
            1293px 283px #fff,
            977px 1608px #fff,
            422px 1252px #fff,
            236px 1653px #fff,
            28px 1722px #fff,
            698px 39px #fff,
            5px 434px #fff,
            1430px 1466px #fff,
            1470px 1596px #fff,
            487px 81px #fff,
            186px 340px #fff,
            1941px 1px #fff,
            1342px 487px #fff,
            813px 1855px #fff,
            1445px 148px #fff,
            1965px 1428px #fff,
            1122px 1277px #fff,
            846px 820px #fff,
            1804px 228px #fff,
            1501px 121px #fff,
            215px 1033px #fff,
            244px 426px #fff,
            976px 453px #fff,
            566px 953px #fff,
            1772px 213px #fff,
            1323px 1778px #fff,
            1350px 817px #fff,
            803px 913px #fff,
            1395px 1359px #fff,
            1995px 119px #fff,
            579px 1963px #fff,
            862px 1621px #fff,
            1718px 825px #fff,
            823px 27px #fff,
            1252px 41px #fff,
            1562px 835px #fff,
            1730px 633px #fff,
            1723px 1939px #fff,
            524px 1824px #fff,
            1921px 1450px #fff,
            1174px 1062px #fff,
            1008px 1932px #fff,
            467px 334px #fff,
            80px 818px #fff,
            1266px 1185px #fff,
            1589px 807px #fff,
            1221px 320px #fff,
            1724px 852px #fff,
            1194px 286px #fff,
            800px 1835px #fff,
            1958px 728px #fff,
            1271px 376px #fff,
            43px 1654px #fff,
            77px 803px #fff,
            1399px 197px #fff,
            591px 1743px #fff,
            652px 1925px #fff,
            107px 1939px #fff,
            1110px 1708px #fff,
            797px 1480px #fff,
            1516px 684px #fff,
            559px 1733px #fff,
            1265px 718px #fff,
            1263px 54px #fff,
            693px 113px #fff,
            1456px 1459px #fff,
            1527px 1324px #fff;
          animation: animStar 50s linear infinite;
        }

        #stars:after {
          content: ' ';
          position: absolute;
          top: 2000px;
          width: 1px;
          height: 1px;
          background: transparent;
          box-shadow:
            1744px 122px #fff,
            134px 1321px #fff,
            92px 859px #fff,
            235px 1045px #fff,
            366px 912px #fff,
            491px 942px #fff,
            14px 1831px #fff,
            582px 476px #fff,
            588px 1230px #fff,
            1520px 1343px #fff,
            1671px 203px #fff,
            550px 341px #fff,
            1491px 549px #fff,
            558px 161px #fff,
            896px 1823px #fff,
            999px 1463px #fff,
            1557px 636px #fff,
            1754px 1307px #fff,
            1682px 1494px #fff,
            703px 1707px #fff,
            1945px 1847px #fff,
            1151px 1320px #fff,
            980px 144px #fff,
            478px 948px #fff,
            109px 1762px #fff,
            558px 255px #fff,
            719px 1820px #fff,
            588px 748px #fff,
            1899px 174px #fff,
            841px 1771px #fff,
            571px 1588px #fff,
            1155px 353px #fff,
            1879px 1220px #fff,
            1782px 870px #fff,
            407px 1238px #fff,
            1141px 63px #fff,
            1147px 1097px #fff,
            1406px 159px #fff,
            637px 1215px #fff,
            694px 1114px #fff,
            1536px 727px #fff,
            1708px 1512px #fff,
            1147px 880px #fff,
            684px 988px #fff,
            140px 851px #fff,
            1565px 1788px #fff,
            1573px 889px #fff,
            1172px 628px #fff,
            1474px 1978px #fff,
            435px 447px #fff,
            185px 1808px #fff,
            620px 1560px #fff,
            1387px 1196px #fff,
            138px 1854px #fff,
            137px 1499px #fff,
            1721px 1132px #fff,
            10px 32px #fff,
            1931px 1990px #fff,
            209px 91px #fff,
            1876px 1795px #fff,
            1130px 1551px #fff,
            284px 1848px #fff,
            389px 1603px #fff,
            612px 1121px #fff,
            825px 1926px #fff,
            161px 344px #fff,
            1729px 333px #fff,
            1037px 37px #fff,
            844px 1085px #fff,
            1179px 554px #fff,
            50px 478px #fff,
            1864px 704px #fff,
            233px 723px #fff,
            1202px 445px #fff,
            882px 189px #fff,
            362px 735px #fff,
            924px 411px #fff,
            902px 209px #fff,
            104px 185px #fff,
            1599px 1852px #fff,
            1974px 944px #fff,
            438px 1164px #fff,
            401px 1533px #fff,
            191px 1429px #fff,
            251px 1034px #fff,
            1807px 1412px #fff,
            72px 23px #fff,
            1752px 1146px #fff,
            261px 1481px #fff,
            548px 33px #fff,
            710px 1204px #fff,
            355px 1697px #fff,
            581px 100px #fff,
            318px 1146px #fff,
            929px 79px #fff,
            999px 347px #fff,
            155px 292px #fff,
            271px 677px #fff,
            920px 1596px #fff,
            1736px 184px #fff,
            1022px 1790px #fff,
            1465px 1762px #fff,
            820px 526px #fff,
            175px 37px #fff,
            440px 746px #fff,
            681px 1879px #fff,
            690px 1135px #fff,
            1960px 1453px #fff,
            422px 856px #fff,
            1217px 1232px #fff,
            1015px 1695px #fff,
            1933px 492px #fff,
            272px 448px #fff,
            1578px 1487px #fff,
            437px 874px #fff,
            947px 838px #fff,
            1339px 867px #fff,
            1484px 773px #fff,
            764px 66px #fff,
            418px 707px #fff,
            192px 1909px #fff,
            1629px 215px #fff,
            171px 260px #fff,
            1180px 220px #fff,
            488px 857px #fff,
            593px 493px #fff,
            1794px 886px #fff,
            1673px 1977px #fff,
            905px 1193px #fff,
            1372px 1843px #fff,
            1605px 908px #fff,
            659px 181px #fff,
            700px 1758px #fff,
            812px 1200px #fff,
            1800px 1440px #fff,
            1858px 212px #fff,
            628px 1026px #fff,
            1825px 1556px #fff,
            1641px 1750px #fff,
            1195px 1086px #fff,
            1465px 558px #fff,
            1634px 436px #fff,
            1354px 1831px #fff,
            1212px 1485px #fff,
            1491px 994px #fff,
            604px 1279px #fff,
            413px 1131px #fff,
            1677px 1086px #fff,
            841px 47px #fff,
            146px 489px #fff,
            117px 1195px #fff,
            67px 47px #fff,
            883px 258px #fff,
            1801px 1294px #fff,
            594px 1870px #fff,
            649px 531px #fff,
            721px 132px #fff,
            975px 1616px #fff,
            716px 94px #fff,
            295px 791px #fff,
            174px 1814px #fff,
            1130px 298px #fff,
            1747px 1835px #fff,
            605px 1521px #fff,
            78px 743px #fff,
            1685px 311px #fff,
            804px 341px #fff,
            1440px 852px #fff,
            1607px 1692px #fff,
            698px 1112px #fff,
            1153px 1608px #fff,
            539px 999px #fff,
            262px 353px #fff,
            457px 1246px #fff,
            1858px 998px #fff,
            364px 431px #fff,
            1907px 912px #fff,
            541px 916px #fff,
            168px 1384px #fff,
            357px 1321px #fff,
            1859px 1866px #fff,
            1001px 909px #fff,
            842px 1663px #fff,
            369px 1176px #fff,
            932px 1372px #fff,
            1606px 732px #fff,
            1844px 857px #fff,
            974px 1588px #fff,
            804px 1139px #fff,
            65px 1213px #fff,
            1066px 863px #fff,
            1991px 1734px #fff,
            300px 738px #fff,
            1260px 1141px #fff,
            83px 260px #fff,
            1219px 167px #fff,
            613px 990px #fff,
            873px 81px #fff,
            362px 235px #fff,
            373px 372px #fff,
            80px 247px #fff,
            902px 1141px #fff,
            294px 464px #fff,
            766px 1925px #fff,
            1151px 1305px #fff,
            1250px 1593px #fff,
            1289px 119px #fff,
            1525px 1505px #fff,
            234px 1306px #fff,
            571px 858px #fff,
            571px 996px #fff,
            766px 1342px #fff,
            1371px 716px #fff,
            711px 1939px #fff,
            904px 1797px #fff,
            424px 1710px #fff,
            762px 1614px #fff,
            1389px 1290px #fff,
            905px 689px #fff,
            352px 38px #fff,
            2000px 1317px #fff,
            597px 864px #fff,
            824px 711px #fff,
            966px 1060px #fff,
            958px 992px #fff,
            1121px 324px #fff,
            1624px 688px #fff,
            1737px 702px #fff,
            1566px 1344px #fff,
            697px 368px #fff,
            291px 1126px #fff,
            1732px 50px #fff,
            712px 1941px #fff,
            1257px 372px #fff,
            317px 1462px #fff,
            222px 309px #fff,
            1139px 647px #fff,
            170px 878px #fff,
            1272px 1511px #fff,
            1009px 1912px #fff,
            1875px 1105px #fff,
            1968px 1370px #fff,
            1193px 687px #fff,
            1498px 611px #fff,
            247px 1993px #fff,
            1686px 1611px #fff,
            937px 340px #fff,
            114px 1423px #fff,
            496px 1191px #fff,
            110px 1284px #fff,
            876px 687px #fff,
            1173px 214px #fff,
            210px 628px #fff,
            302px 1173px #fff,
            1318px 939px #fff,
            1665px 1310px #fff,
            66px 338px #fff,
            1355px 615px #fff,
            1139px 1672px #fff,
            91px 1000px #fff,
            112px 422px #fff,
            89px 503px #fff,
            97px 1599px #fff,
            1031px 1955px #fff,
            1755px 1532px #fff,
            1764px 1930px #fff,
            1822px 1751px #fff,
            133px 401px #fff,
            718px 85px #fff,
            1797px 78px #fff,
            520px 1725px #fff,
            226px 406px #fff,
            1561px 1574px #fff,
            340px 1602px #fff,
            718px 976px #fff,
            1823px 570px #fff,
            1298px 484px #fff,
            1023px 1903px #fff,
            1266px 1334px #fff,
            763px 877px #fff,
            223px 631px #fff,
            1761px 1293px #fff,
            878px 1px #fff,
            973px 696px #fff,
            975px 1841px #fff,
            1276px 302px #fff,
            1399px 1324px #fff,
            1706px 872px #fff,
            341px 1960px #fff,
            1384px 1655px #fff,
            1897px 767px #fff,
            68px 405px #fff,
            705px 310px #fff,
            194px 103px #fff,
            1432px 368px #fff,
            1262px 1682px #fff,
            1933px 1526px #fff,
            263px 655px #fff,
            1031px 1031px #fff,
            1557px 788px #fff,
            1558px 1360px #fff,
            812px 421px #fff,
            1951px 734px #fff,
            1567px 498px #fff,
            1832px 1229px #fff,
            913px 164px #fff,
            1907px 1273px #fff,
            533px 749px #fff,
            1235px 951px #fff,
            645px 1959px #fff,
            92px 1570px #fff,
            1169px 1434px #fff,
            118px 1223px #fff,
            583px 957px #fff,
            1245px 316px #fff,
            527px 863px #fff,
            372px 1007px #fff,
            244px 1251px #fff,
            314px 494px #fff,
            1842px 1463px #fff,
            1315px 730px #fff,
            1655px 1366px #fff,
            482px 979px #fff,
            340px 1808px #fff,
            256px 578px #fff,
            858px 1792px #fff,
            1965px 403px #fff,
            454px 253px #fff,
            1664px 698px #fff,
            1974px 750px #fff,
            262px 1801px #fff,
            1652px 1076px #fff,
            992px 711px #fff,
            1792px 1323px #fff,
            700px 1748px #fff,
            855px 966px #fff,
            1833px 1747px #fff,
            402px 1272px #fff,
            1391px 1039px #fff,
            1395px 1538px #fff,
            397px 1972px #fff,
            744px 1403px #fff,
            1784px 1046px #fff,
            111px 198px #fff,
            1827px 473px #fff,
            1347px 1891px #fff,
            1238px 1081px #fff,
            189px 908px #fff,
            1513px 542px #fff,
            673px 981px #fff,
            720px 1184px #fff,
            373px 792px #fff,
            1470px 1631px #fff,
            16px 822px #fff,
            1935px 829px #fff,
            364px 613px #fff,
            223px 899px #fff,
            1233px 874px #fff,
            1441px 1834px #fff,
            719px 894px #fff,
            76px 1610px #fff,
            296px 685px #fff,
            572px 1637px #fff,
            349px 114px #fff,
            1670px 1802px #fff,
            979px 928px #fff,
            1589px 574px #fff,
            756px 194px #fff,
            616px 507px #fff,
            271px 186px #fff,
            198px 360px #fff,
            1916px 418px #fff,
            721px 667px #fff,
            1937px 1908px #fff,
            17px 556px #fff,
            345px 443px #fff,
            718px 760px #fff,
            1922px 310px #fff,
            1185px 887px #fff,
            1745px 1848px #fff,
            909px 847px #fff,
            1170px 134px #fff,
            1773px 1133px #fff,
            1099px 233px #fff,
            1122px 1524px #fff,
            866px 907px #fff,
            398px 213px #fff,
            1287px 886px #fff,
            1194px 149px #fff,
            1876px 1667px #fff,
            332px 1658px #fff,
            146px 1253px #fff,
            958px 211px #fff,
            643px 47px #fff,
            1213px 1339px #fff,
            1637px 1939px #fff,
            1601px 355px #fff,
            256px 1982px #fff,
            989px 1609px #fff,
            1179px 701px #fff,
            685px 1463px #fff,
            1910px 465px #fff,
            764px 1930px #fff,
            1856px 433px #fff,
            720px 367px #fff,
            1821px 416px #fff,
            48px 1114px #fff,
            716px 489px #fff,
            174px 1972px #fff,
            115px 1373px #fff,
            1487px 141px #fff,
            1919px 1362px #fff,
            1662px 859px #fff,
            1168px 490px #fff,
            706px 1820px #fff,
            588px 318px #fff,
            107px 778px #fff,
            621px 1667px #fff,
            1331px 1996px #fff,
            1404px 1154px #fff,
            860px 1019px #fff,
            1385px 1883px #fff,
            1066px 1951px #fff,
            752px 444px #fff,
            955px 1031px #fff,
            1256px 1899px #fff,
            961px 1455px #fff,
            1101px 84px #fff,
            890px 335px #fff,
            798px 40px #fff,
            138px 54px #fff,
            1934px 252px #fff,
            429px 324px #fff,
            1645px 1522px #fff,
            218px 737px #fff,
            1625px 531px #fff,
            605px 1290px #fff,
            1867px 648px #fff,
            82px 1137px #fff,
            231px 1423px #fff,
            471px 944px #fff,
            1363px 1752px #fff,
            482px 1364px #fff,
            1305px 627px #fff,
            1065px 891px #fff,
            217px 1841px #fff,
            1843px 1501px #fff,
            1681px 734px #fff,
            185px 856px #fff,
            609px 1094px #fff,
            49px 1657px #fff,
            783px 594px #fff,
            1018px 1123px #fff,
            644px 1149px #fff,
            739px 1262px #fff,
            1439px 1187px #fff,
            1789px 1507px #fff,
            941px 791px #fff,
            1319px 1308px #fff,
            498px 626px #fff,
            1594px 858px #fff,
            783px 71px #fff,
            576px 1976px #fff,
            1625px 696px #fff,
            1821px 352px #fff,
            1039px 885px #fff,
            208px 430px #fff,
            124px 198px #fff,
            1964px 686px #fff,
            1440px 1952px #fff,
            231px 1435px #fff,
            1846px 853px #fff,
            1174px 536px #fff,
            1228px 310px #fff,
            1380px 699px #fff,
            1116px 169px #fff,
            1416px 1007px #fff,
            178px 192px #fff,
            1302px 1903px #fff,
            1892px 154px #fff,
            962px 1032px #fff,
            1009px 1916px #fff,
            1549px 1206px #fff,
            899px 968px #fff,
            902px 543px #fff,
            1516px 542px #fff,
            965px 527px #fff,
            1594px 696px #fff,
            856px 1719px #fff,
            1522px 248px #fff,
            1795px 283px #fff,
            1144px 946px #fff,
            1365px 199px #fff,
            359px 1347px #fff,
            1506px 263px #fff,
            433px 1793px #fff,
            201px 247px #fff,
            942px 838px #fff,
            1122px 1065px #fff,
            1997px 108px #fff,
            306px 989px #fff,
            641px 1713px #fff,
            1072px 959px #fff,
            1874px 1079px #fff,
            1938px 269px #fff,
            891px 389px #fff,
            22px 574px #fff,
            727px 474px #fff,
            1671px 1649px #fff,
            98px 400px #fff,
            641px 798px #fff,
            1218px 1051px #fff,
            907px 1485px #fff,
            1002px 1549px #fff,
            1932px 1448px #fff,
            1132px 573px #fff,
            1222px 1147px #fff,
            1009px 451px #fff,
            698px 1940px #fff,
            97px 1123px #fff,
            904px 751px #fff,
            1904px 593px #fff,
            1451px 1658px #fff,
            191px 1606px #fff,
            1346px 482px #fff,
            1935px 1086px #fff,
            1052px 224px #fff,
            526px 1810px #fff,
            1607px 606px #fff,
            427px 1830px #fff,
            1546px 407px #fff,
            1771px 208px #fff,
            1714px 181px #fff,
            1332px 943px #fff,
            1663px 1094px #fff,
            1861px 1747px #fff,
            1429px 1217px #fff,
            1733px 1915px #fff,
            925px 1179px #fff,
            735px 781px #fff,
            1775px 1989px #fff,
            1770px 476px #fff,
            844px 400px #fff,
            1793px 1517px #fff,
            544px 281px #fff,
            725px 344px #fff,
            1274px 764px #fff,
            1845px 339px #fff,
            582px 1745px #fff,
            351px 1287px #fff,
            207px 749px #fff,
            1063px 1411px #fff,
            1246px 342px #fff,
            1538px 166px #fff,
            770px 602px #fff,
            1214px 1971px #fff,
            113px 294px #fff,
            1157px 101px #fff,
            1657px 517px #fff,
            637px 1693px #fff,
            961px 1649px #fff,
            1754px 1572px #fff,
            1885px 659px #fff,
            238px 34px #fff,
            413px 1027px #fff,
            1709px 1556px #fff,
            460px 878px #fff,
            1597px 608px #fff,
            544px 434px #fff,
            1258px 477px #fff,
            565px 1863px #fff,
            426px 322px #fff,
            1917px 850px #fff,
            296px 144px #fff,
            643px 1896px #fff,
            511px 116px #fff,
            359px 847px #fff,
            702px 1133px #fff,
            857px 1371px #fff,
            559px 1758px #fff,
            1615px 1223px #fff,
            1927px 1736px #fff,
            704px 941px #fff,
            1932px 942px #fff,
            1462px 335px #fff,
            154px 1915px #fff,
            1387px 918px #fff,
            1512px 1041px #fff,
            1094px 1334px #fff,
            1678px 865px #fff,
            1960px 431px #fff,
            666px 1258px #fff,
            422px 1215px #fff,
            345px 802px #fff,
            1207px 647px #fff,
            1701px 374px #fff,
            1440px 1925px #fff,
            1536px 1163px #fff,
            905px 155px #fff,
            843px 1036px #fff,
            660px 823px #fff,
            1329px 661px #fff,
            1867px 899px #fff,
            1022px 514px #fff,
            1062px 1570px #fff,
            888px 1786px #fff,
            699px 1738px #fff,
            1831px 1712px #fff,
            434px 18px #fff,
            227px 205px #fff,
            520px 1279px #fff,
            1329px 764px #fff,
            1824px 47px #fff,
            112px 266px #fff,
            830px 1140px #fff,
            478px 637px #fff,
            634px 850px #fff,
            711px 873px #fff,
            987px 249px #fff,
            1454px 909px #fff,
            699px 922px #fff,
            1458px 1142px #fff,
            701px 1893px #fff,
            134px 384px #fff,
            904px 354px #fff,
            1913px 1229px #fff,
            1606px 1336px #fff,
            976px 1776px #fff,
            1726px 605px #fff,
            1244px 590px #fff,
            1351px 526px #fff,
            1997px 130px #fff,
            1137px 1134px #fff,
            1293px 283px #fff,
            977px 1608px #fff,
            422px 1252px #fff,
            236px 1653px #fff,
            28px 1722px #fff,
            698px 39px #fff,
            5px 434px #fff,
            1430px 1466px #fff,
            1470px 1596px #fff,
            487px 81px #fff,
            186px 340px #fff,
            1941px 1px #fff,
            1342px 487px #fff,
            813px 1855px #fff,
            1445px 148px #fff,
            1965px 1428px #fff,
            1122px 1277px #fff,
            846px 820px #fff,
            1804px 228px #fff,
            1501px 121px #fff,
            215px 1033px #fff,
            244px 426px #fff,
            976px 453px #fff,
            566px 953px #fff,
            1772px 213px #fff,
            1323px 1778px #fff,
            1350px 817px #fff,
            803px 913px #fff,
            1395px 1359px #fff,
            1995px 119px #fff,
            579px 1963px #fff,
            862px 1621px #fff,
            1718px 825px #fff,
            823px 27px #fff,
            1252px 41px #fff,
            1562px 835px #fff,
            1730px 633px #fff,
            1723px 1939px #fff,
            524px 1824px #fff,
            1921px 1450px #fff,
            1174px 1062px #fff,
            1008px 1932px #fff,
            467px 334px #fff,
            80px 818px #fff,
            1266px 1185px #fff,
            1589px 807px #fff,
            1221px 320px #fff,
            1724px 852px #fff,
            1194px 286px #fff,
            800px 1835px #fff,
            1958px 728px #fff,
            1271px 376px #fff,
            43px 1654px #fff,
            77px 803px #fff,
            1399px 197px #fff,
            591px 1743px #fff,
            652px 1925px #fff,
            107px 1939px #fff,
            1110px 1708px #fff,
            797px 1480px #fff,
            1516px 684px #fff,
            559px 1733px #fff,
            1265px 718px #fff,
            1263px 54px #fff,
            693px 113px #fff,
            1456px 1459px #fff,
            1527px 1324px #fff;
        }

        #stars2 {
          width: 2px;
          height: 2px;
          background: transparent;
          box-shadow:
            1907px 1575px #fff,
            893px 268px #fff,
            1819px 666px #fff,
            366px 1985px #fff,
            1736px 1062px #fff,
            1124px 45px #fff,
            905px 1674px #fff,
            1570px 711px #fff,
            255px 1747px #fff,
            147px 1032px #fff,
            865px 1813px #fff,
            1721px 1461px #fff,
            202px 724px #fff,
            1860px 812px #fff,
            634px 1410px #fff,
            927px 1264px #fff,
            1648px 620px #fff,
            1915px 1309px #fff,
            731px 778px #fff,
            91px 1185px #fff,
            829px 1003px #fff,
            174px 1784px #fff,
            1094px 1874px #fff,
            1464px 484px #fff,
            1639px 1717px #fff,
            959px 573px #fff,
            1686px 1287px #fff,
            906px 356px #fff,
            970px 499px #fff,
            626px 890px #fff,
            1344px 1070px #fff,
            1351px 544px #fff,
            708px 68px #fff,
            1870px 130px #fff,
            556px 1270px #fff,
            1432px 618px #fff,
            769px 673px #fff,
            1289px 1410px #fff,
            1499px 1969px #fff,
            749px 1163px #fff,
            1198px 198px #fff,
            399px 133px #fff,
            1856px 1280px #fff,
            904px 194px #fff,
            973px 1107px #fff,
            1371px 1785px #fff,
            989px 1835px #fff,
            1043px 1517px #fff,
            787px 916px #fff,
            1635px 1155px #fff,
            752px 524px #fff,
            1926px 1279px #fff,
            1052px 1163px #fff,
            719px 1963px #fff,
            786px 519px #fff,
            1440px 500px #fff,
            156px 1265px #fff,
            1691px 429px #fff,
            1349px 1474px #fff,
            736px 459px #fff,
            248px 1678px #fff,
            1136px 510px #fff,
            1317px 10px #fff,
            1209px 1323px #fff,
            54px 469px #fff,
            730px 1939px #fff,
            960px 23px #fff,
            112px 941px #fff,
            1852px 1522px #fff,
            1993px 1972px #fff,
            265px 129px #fff,
            222px 1897px #fff,
            1766px 1804px #fff,
            1530px 1786px #fff,
            1045px 432px #fff,
            1916px 1980px #fff,
            309px 1780px #fff,
            1420px 559px #fff,
            1708px 103px #fff,
            324px 1407px #fff,
            1175px 943px #fff,
            80px 1221px #fff,
            1016px 1933px #fff,
            318px 560px #fff,
            190px 1489px #fff,
            1095px 892px #fff,
            1975px 1191px #fff,
            1197px 955px #fff,
            1482px 873px #fff,
            1912px 300px #fff,
            2000px 1636px #fff,
            1473px 1808px #fff,
            507px 192px #fff,
            924px 1300px #fff,
            1485px 1636px #fff,
            41px 1822px #fff,
            689px 120px #fff,
            769px 273px #fff,
            1039px 976px #fff,
            1057px 1520px #fff,
            1417px 1187px #fff,
            512px 1507px #fff,
            519px 45px #fff,
            1207px 1687px #fff,
            1824px 1488px #fff,
            1694px 31px #fff,
            1859px 52px #fff,
            1368px 268px #fff,
            190px 1404px #fff,
            940px 1003px #fff,
            167px 942px #fff,
            1641px 647px #fff,
            1051px 654px #fff,
            233px 1640px #fff,
            554px 511px #fff,
            1705px 1866px #fff,
            240px 1430px #fff,
            140px 668px #fff,
            648px 1617px #fff,
            252px 101px #fff,
            676px 1774px #fff,
            889px 1698px #fff,
            595px 853px #fff,
            249px 1768px #fff,
            1091px 1529px #fff,
            223px 839px #fff,
            239px 699px #fff,
            1547px 356px #fff,
            249px 1130px #fff,
            1209px 1946px #fff,
            1967px 1274px #fff,
            430px 298px #fff,
            1305px 134px #fff,
            156px 91px #fff,
            1712px 264px #fff,
            1256px 991px #fff,
            1887px 1844px #fff,
            1957px 1275px #fff,
            1718px 666px #fff,
            1090px 543px #fff,
            933px 1411px #fff,
            801px 323px #fff,
            202px 1659px #fff,
            1117px 549px #fff,
            290px 1859px #fff,
            1380px 1516px #fff,
            557px 1165px #fff,
            1953px 1758px #fff,
            1901px 1407px #fff,
            1144px 347px #fff,
            1640px 271px #fff,
            1996px 43px #fff,
            838px 488px #fff,
            1151px 1529px #fff,
            306px 460px #fff,
            1666px 635px #fff,
            1572px 633px #fff,
            1797px 1076px #fff,
            1727px 1119px #fff,
            474px 871px #fff,
            1491px 1398px #fff,
            1301px 868px #fff,
            1632px 454px #fff,
            1611px 466px #fff,
            498px 1162px #fff,
            796px 269px #fff,
            1988px 747px #fff,
            502px 12px #fff,
            276px 1260px #fff,
            1536px 974px #fff,
            956px 656px #fff,
            107px 701px #fff,
            965px 491px #fff,
            1646px 247px #fff,
            1515px 921px #fff,
            1813px 519px #fff,
            697px 143px #fff,
            591px 1452px #fff,
            1773px 1411px #fff,
            1622px 1401px #fff,
            534px 340px #fff,
            1590px 284px #fff,
            1464px 528px #fff,
            734px 1718px #fff,
            1929px 1683px #fff,
            656px 480px #fff,
            833px 705px #fff,
            1567px 109px #fff,
            1055px 330px #fff,
            1943px 1367px #fff,
            1935px 1512px #fff,
            355px 1024px #fff,
            1894px 218px #fff,
            1703px 1772px #fff,
            781px 1394px #fff,
            367px 42px #fff,
            100px 690px #fff,
            1831px 458px #fff,
            1884px 1878px #fff,
            814px 1013px #fff;
          animation: animStar 100s linear infinite;
        }

        #stars2:after {
          content: ' ';
          position: absolute;
          top: 2000px;
          width: 2px;
          height: 2px;
          background: transparent;
          box-shadow:
            1907px 1575px #fff,
            893px 268px #fff,
            1819px 666px #fff,
            366px 1985px #fff,
            1736px 1062px #fff,
            1124px 45px #fff,
            905px 1674px #fff,
            1570px 711px #fff,
            255px 1747px #fff,
            147px 1032px #fff,
            865px 1813px #fff,
            1721px 1461px #fff,
            202px 724px #fff,
            1860px 812px #fff,
            634px 1410px #fff,
            927px 1264px #fff,
            1648px 620px #fff,
            1915px 1309px #fff,
            731px 778px #fff,
            91px 1185px #fff,
            829px 1003px #fff,
            174px 1784px #fff,
            1094px 1874px #fff,
            1464px 484px #fff,
            1639px 1717px #fff,
            959px 573px #fff,
            1686px 1287px #fff,
            906px 356px #fff,
            970px 499px #fff,
            626px 890px #fff,
            1344px 1070px #fff,
            1351px 544px #fff,
            708px 68px #fff,
            1870px 130px #fff,
            556px 1270px #fff,
            1432px 618px #fff,
            769px 673px #fff,
            1289px 1410px #fff,
            1499px 1969px #fff,
            749px 1163px #fff,
            1198px 198px #fff,
            399px 133px #fff,
            1856px 1280px #fff,
            904px 194px #fff,
            973px 1107px #fff,
            1371px 1785px #fff,
            989px 1835px #fff,
            1043px 1517px #fff,
            787px 916px #fff,
            1635px 1155px #fff,
            752px 524px #fff,
            1926px 1279px #fff,
            1052px 1163px #fff,
            719px 1963px #fff,
            786px 519px #fff,
            1440px 500px #fff,
            156px 1265px #fff,
            1691px 429px #fff,
            1349px 1474px #fff,
            736px 459px #fff,
            248px 1678px #fff,
            1136px 510px #fff,
            1317px 10px #fff,
            1209px 1323px #fff,
            54px 469px #fff,
            730px 1939px #fff,
            960px 23px #fff,
            112px 941px #fff,
            1852px 1522px #fff,
            1993px 1972px #fff,
            265px 129px #fff,
            222px 1897px #fff,
            1766px 1804px #fff,
            1530px 1786px #fff,
            1045px 432px #fff,
            1916px 1980px #fff,
            309px 1780px #fff,
            1420px 559px #fff,
            1708px 103px #fff,
            324px 1407px #fff,
            1175px 943px #fff,
            80px 1221px #fff,
            1016px 1933px #fff,
            318px 560px #fff,
            190px 1489px #fff,
            1095px 892px #fff,
            1975px 1191px #fff,
            1197px 955px #fff,
            1482px 873px #fff,
            1912px 300px #fff,
            2000px 1636px #fff,
            1473px 1808px #fff,
            507px 192px #fff,
            924px 1300px #fff,
            1485px 1636px #fff,
            41px 1822px #fff,
            689px 120px #fff,
            769px 273px #fff,
            1039px 976px #fff,
            1057px 1520px #fff,
            1417px 1187px #fff,
            512px 1507px #fff,
            519px 45px #fff,
            1207px 1687px #fff,
            1824px 1488px #fff,
            1694px 31px #fff,
            1859px 52px #fff,
            1368px 268px #fff,
            190px 1404px #fff,
            940px 1003px #fff,
            167px 942px #fff,
            1641px 647px #fff,
            1051px 654px #fff,
            233px 1640px #fff,
            554px 511px #fff,
            1705px 1866px #fff,
            240px 1430px #fff,
            140px 668px #fff,
            648px 1617px #fff,
            252px 101px #fff,
            676px 1774px #fff,
            889px 1698px #fff,
            595px 853px #fff,
            249px 1768px #fff,
            1091px 1529px #fff,
            223px 839px #fff,
            239px 699px #fff,
            1547px 356px #fff,
            249px 1130px #fff,
            1209px 1946px #fff,
            1967px 1274px #fff,
            430px 298px #fff,
            1305px 134px #fff,
            156px 91px #fff,
            1712px 264px #fff,
            1256px 991px #fff,
            1887px 1844px #fff,
            1957px 1275px #fff,
            1718px 666px #fff,
            1090px 543px #fff,
            933px 1411px #fff,
            801px 323px #fff,
            202px 1659px #fff,
            1117px 549px #fff,
            290px 1859px #fff,
            1380px 1516px #fff,
            557px 1165px #fff,
            1953px 1758px #fff,
            1901px 1407px #fff,
            1144px 347px #fff,
            1640px 271px #fff,
            1996px 43px #fff,
            838px 488px #fff,
            1151px 1529px #fff,
            306px 460px #fff,
            1666px 635px #fff,
            1572px 633px #fff,
            1797px 1076px #fff,
            1727px 1119px #fff,
            474px 871px #fff,
            1491px 1398px #fff,
            1301px 868px #fff,
            1632px 454px #fff,
            1611px 466px #fff,
            498px 1162px #fff,
            796px 269px #fff,
            1988px 747px #fff,
            502px 12px #fff,
            276px 1260px #fff,
            1536px 974px #fff,
            956px 656px #fff,
            107px 701px #fff,
            965px 491px #fff,
            1646px 247px #fff,
            1515px 921px #fff,
            1813px 519px #fff,
            697px 143px #fff,
            591px 1452px #fff,
            1773px 1411px #fff,
            1622px 1401px #fff,
            534px 340px #fff,
            1590px 284px #fff,
            1464px 528px #fff,
            734px 1718px #fff,
            1929px 1683px #fff,
            656px 480px #fff,
            833px 705px #fff,
            1567px 109px #fff,
            1055px 330px #fff,
            1943px 1367px #fff,
            1935px 1512px #fff,
            355px 1024px #fff,
            1894px 218px #fff,
            1703px 1772px #fff,
            781px 1394px #fff,
            367px 42px #fff,
            100px 690px #fff,
            1831px 458px #fff,
            1884px 1878px #fff,
            814px 1013px #fff;
        }

        #stars3 {
          width: 3px;
          height: 3px;
          background: transparent;
          box-shadow:
            1994px 1692px #fff,
            961px 1928px #fff,
            819px 1320px #fff,
            1899px 52px #fff,
            28px 1937px #fff,
            157px 88px #fff,
            860px 1909px #fff,
            720px 427px #fff,
            1319px 1469px #fff,
            677px 960px #fff,
            1265px 1181px #fff,
            1176px 1361px #fff,
            1957px 753px #fff,
            1674px 1355px #fff,
            929px 1206px #fff,
            1038px 898px #fff,
            1229px 365px #fff,
            1085px 51px #fff,
            851px 650px #fff,
            385px 21px #fff,
            41px 1153px #fff,
            718px 70px #fff,
            1321px 579px #fff,
            497px 1458px #fff,
            1981px 813px #fff,
            776px 1187px #fff,
            1345px 31px #fff,
            1571px 400px #fff,
            339px 587px #fff,
            146px 750px #fff,
            64px 61px #fff,
            307px 981px #fff,
            388px 849px #fff,
            1398px 936px #fff,
            522px 765px #fff,
            1146px 350px #fff,
            19px 206px #fff,
            1767px 319px #fff,
            1203px 425px #fff,
            1917px 1018px #fff,
            91px 1401px #fff,
            898px 697px #fff,
            1712px 1121px #fff,
            633px 1119px #fff,
            137px 1266px #fff,
            1006px 931px #fff,
            1644px 1804px #fff,
            1596px 1057px #fff,
            1298px 543px #fff,
            1505px 996px #fff,
            1972px 1383px #fff,
            1210px 1497px #fff,
            804px 1840px #fff,
            913px 1047px #fff,
            187px 1824px #fff,
            1564px 1994px #fff,
            557px 1737px #fff,
            508px 1609px #fff,
            1259px 1911px #fff,
            1294px 1381px #fff,
            1845px 1843px #fff,
            667px 1497px #fff,
            1046px 945px #fff,
            937px 1889px #fff,
            982px 1806px #fff,
            1293px 671px #fff,
            668px 904px #fff,
            358px 1971px #fff,
            133px 766px #fff,
            1152px 1573px #fff,
            318px 694px #fff,
            578px 1184px #fff,
            1620px 1701px #fff,
            1359px 354px #fff,
            1807px 1367px #fff,
            458px 911px #fff,
            1387px 806px #fff,
            523px 81px #fff,
            1243px 341px #fff,
            906px 1730px #fff,
            1629px 474px #fff,
            1690px 419px #fff,
            847px 808px #fff,
            528px 1627px #fff,
            480px 718px #fff,
            537px 593px #fff,
            1432px 1333px #fff,
            200px 1228px #fff,
            1800px 1375px #fff,
            725px 1231px #fff,
            1749px 1432px #fff,
            1948px 303px #fff,
            1889px 252px #fff,
            1114px 1400px #fff,
            759px 646px #fff,
            222px 571px #fff,
            1908px 1401px #fff,
            1856px 1204px #fff,
            338px 1645px #fff,
            1528px 1050px #fff;
          animation: animStar 150s linear infinite;
        }

        #stars3:after {
          content: '';
          position: absolute;
          top: 2000px;
          background: transparent;
          box-shadow:
            1994px 1692px #fff,
            961px 1928px #fff,
            819px 1320px #fff,
            1899px 52px #fff,
            28px 1937px #fff,
            157px 88px #fff,
            860px 1909px #fff,
            720px 427px #fff,
            1319px 1469px #fff,
            677px 960px #fff,
            1265px 1181px #fff,
            1176px 1361px #fff,
            1957px 753px #fff,
            1674px 1355px #fff,
            929px 1206px #fff,
            1038px 898px #fff,
            1229px 365px #fff,
            1085px 51px #fff,
            851px 650px #fff,
            385px 21px #fff,
            41px 1153px #fff,
            718px 70px #fff,
            1321px 579px #fff,
            497px 1458px #fff,
            1981px 813px #fff,
            776px 1187px #fff,
            1345px 31px #fff,
            1571px 400px #fff,
            339px 587px #fff,
            146px 750px #fff,
            64px 61px #fff,
            307px 981px #fff,
            388px 849px #fff,
            1398px 936px #fff,
            522px 765px #fff,
            1146px 350px #fff,
            19px 206px #fff,
            1767px 319px #fff,
            1203px 425px #fff,
            1917px 1018px #fff,
            91px 1401px #fff,
            898px 697px #fff,
            1712px 1121px #fff,
            633px 1119px #fff,
            137px 1266px #fff,
            1006px 931px #fff,
            1644px 1804px #fff,
            1596px 1057px #fff,
            1298px 543px #fff,
            1505px 996px #fff,
            1972px 1383px #fff,
            1210px 1497px #fff,
            804px 1840px #fff,
            913px 1047px #fff,
            187px 1824px #fff,
            1564px 1994px #fff,
            557px 1737px #fff,
            508px 1609px #fff,
            1259px 1911px #fff,
            1294px 1381px #fff,
            1845px 1843px #fff,
            667px 1497px #fff,
            1046px 945px #fff,
            937px 1889px #fff,
            982px 1806px #fff,
            1293px 671px #fff,
            668px 904px #fff,
            358px 1971px #fff,
            133px 766px #fff,
            1152px 1573px #fff,
            318px 694px #fff,
            578px 1184px #fff,
            1620px 1701px #fff,
            1359px 354px #fff,
            1807px 1367px #fff,
            458px 911px #fff,
            1387px 806px #fff,
            523px 81px #fff,
            1243px 341px #fff,
            906px 1730px #fff,
            1629px 474px #fff,
            1690px 419px #fff,
            847px 808px #fff,
            528px 1627px #fff,
            480px 718px #fff,
            537px 593px #fff,
            1432px 1333px #fff,
            200px 1228px #fff,
            1800px 1375px #fff,
            725px 1231px #fff,
            1749px 1432px #fff,
            1948px 303px #fff,
            1889px 252px #fff,
            1114px 1400px #fff,
            759px 646px #fff,
            222px 571px #fff,
            1908px 1401px #fff,
            1856px 1204px #fff,
            338px 1645px #fff,
            1528px 1050px #fff;
        }
        .card-rounded {
          border-radius: 20px 20px 20px 20px;
        }
        #header-11 {
          position: absolute;
          right: 0;
          top: 0px;
          min-width: 100%;
          min-height: 850px;
          max-height: 850px;
        }
        #next {
          position: absolute;
          left: 105%;
          top: 41%;
        }

        #prev {
          position: absolute;
          right: 105%;
          top: 41%;
        }

        .perspective-1000 {
          perspective: 1000px;
          display: inline-flex;
          width: 154px;
          height: 40px;
        }

        .perspective-1000 a {
          transform-style: preserve-3d;
          transform: translateZ(-0px);
          transition: transform 0.25s;
        }

        span.contact a:before,
        span a:after {
          position: absolute;
          content: 'Our Portfolio';
          height: 40px;
          width: 154px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid #8dc63f;
          box-sizing: border-box;
          border-radius: 5px;
          color: #fff;
          font-size: 18px;
          overflow: hidden;
        }

        #findUsContact a:before,
        #findUsContact a:after {
          content: 'We’r Hiring';
        }
        .ml-200 {
          margin-left: 200px;
        }

        span a:before {
          background: #8dc63f;
          transform: rotateY(0deg) translateZ(15px);
        }

        span.contact2 a:before,
        .contact2 a:after {
          content: ' Testimonials';
          border: 2px solid #670f7a;
          width: 170px;
        }

        .contact2 a:before {
          background: #670f7a;
          transform: rotateY(0deg) translateZ(15px);
        }

        span a:after {
          font-weight: 400;
          color: #8dc63f;
          transform: rotateX(90deg) translateZ(15px);
        }

        .contact2 a:after {
          color: #670f7a;
        }

        span a:hover {
          transform: translateZ(-15px) rotateX(-90deg);
        }

        .d-none {
          display: none;
        }

        .header-mp4 {
          object-fit: cover;
        }

        @media screen and (max-width: 400px) {
          .c2 {
            margin-left: 5px;
          }
        }
        @media screen and (max-width: 350px) {
          .c2 {
            margin-left: 0px;
          }
        }
        .bg-dark {
          background-image: url('/uploads/images/home-page/dark-BG.png');
          background-color: gba(0, 0, 0, 0.57);
          background-size: cover;
          background-position: bottom right;
          background-blend-mode: screen;
        }
        .calc {
          top: calc(50% - 45px);
          left: calc(50% - 60px);
        }
        .big-text-anim-1 {
          opacity: 0;
          -webkit-animation: text-focus-in 1s cubic-bezier(0.215, 0.61, 0.355, 1) both;
          animation: text-focus-in 1s cubic-bezier(0.215, 0.61, 0.355, 1) both;
        }
        .big-text-anim-2 {
          opacity: 0;
          -webkit-animation: text-focus-in 3s cubic-bezier(0.56, 0.88, 0.89, 0.89) both;
          animation: text-focus-in 3s cubic-bezier(0.56, 0.88, 0.89, 0.89) both;
          animation-delay: 4․2s;
        }
        .scale-up-center-7 {
          transform: translateX(-1000px);
          -webkit-animation: slide-in-left 2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation: slide-in-left 2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation-delay: 1s;
        }
        .scale-up-center-6 {
          transform: translateX(-1000px);
          -webkit-animation: slide-in-left 2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation: slide-in-left 2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation-delay: 1.2s;
        }
        .scale-up-center-4 {
          transform: translateX(-1000px);
          -webkit-animation: slide-in-left 2.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation: slide-in-left 2.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation-delay: 1.2s;
        }

        .scale-up-center-5 {
          transform: translateX(-1000px);
          -webkit-animation: slide-in-left 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation: slide-in-left 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation-delay: 1.5s;
        }
        .scale-up-center-3 {
          transform: translateX(-1000px);
          -webkit-animation: slide-in-left 2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation: slide-in-left 2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation-delay: 1.9s;
        }
        .scale-up-center-2 {
          transform: translateX(-1000px);
          -webkit-animation: slide-in-left 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation: slide-in-left 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation-delay: 2.2s;
        }
        .scale-up-center-1 {
          transform: translateX(-1000px);
          -webkit-animation: slide-in-left 2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation: slide-in-left 2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation-delay: 2s;
        }
        @-webkit-keyframes text-focus-in {
          0% {
            -webkit-filter: blur(12px);
            filter: blur(12px);
            opacity: 0;
          }
          100% {
            -webkit-filter: blur(0px);
            filter: blur(0px);
            opacity: 1;
          }
        }
        @keyframes text-focus-in {
          0% {
            -webkit-filter: blur(12px);
            filter: blur(12px);
            opacity: 0;
          }
          100% {
            -webkit-filter: blur(0px);
            filter: blur(0px);
            opacity: 1;
          }
        }
        @-webkit-keyframes tracking-in-expand {
          0% {
            opacity: 0;
          }
          40% {
            opacity: 0.6;
          }
          100% {
            opacity: 1;
          }
        }
        @keyframes tracking-in-expand {
          0% {
            opacity: 0;
          }
          40% {
            opacity: 0.6;
          }
          100% {
            opacity: 1;
          }
        }
        @-webkit-keyframes scale-up-center {
          0% {
            -webkit-transform: scale(0);
            transform: scale(0);
          }
          100% {
            -webkit-transform: scale(1);
            transform: scale(1);
          }
        }
        @keyframes scale-up-center {
          0% {
            -webkit-transform: scale(0);
            transform: scale(0);
          }
          100% {
            -webkit-transform: scale(1);
            transform: scale(1);
          }
        }

        .scale-0 {
          transform: scale(0);
        }

        @-webkit-keyframes slide-in-left {
          0% {
            -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
            opacity: 0;
          }
          100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
          }
        }
        @keyframes slide-in-left {
          0% {
            -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
            opacity: 0;
          }
          100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
          }
        }
        .scale-up-center-second-1 {
          -webkit-animation: slide-in-left-2 1.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation: slide-in-left-2 1.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation-delay: 4s;
        }
        .scale-up-center-second-2 {
          -webkit-animation: slide-in-left-2 1.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation: slide-in-left-2 1.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation-delay: 4.5s;
        }
        .scale-up-center-second-3 {
          -webkit-animation: slide-in-left-2 1.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation: slide-in-left-2 1.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation-delay: 4.7s;
        }
        @keyframes slide-in-left-2 {
          0% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
          }
          100% {
            -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
            opacity: 1;
          }
        }
        .scale-up-top-second-3 {
          -webkit-animation: slide-in-top-2 1.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation: slide-in-top-2 1.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation-delay: 4.7s;
        }
        @keyframes slide-in-top-2 {
          0% {
            -webkit-transform: translateY(0) scale(1);
            transform: translateY(0) scale(1);
            opacity: 1;
          }
          100% {
            -webkit-transform: translateY(-100px) scale(0.6);
            transform: translateY(-100px) scale(0.6);
            opacity: 1;
          }
        }
        .scale-up-center-second-3-right {
          -webkit-animation: slide-in-left-2-right 1.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation: slide-in-left-2-right 1.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation-delay: 4.7s;
        }
        @keyframes slide-in-left-2-right {
          0% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
          }
          100% {
            -webkit-transform: translateX(50px);
            transform: translateX(50px);
            opacity: 1;
          }
        }
        .bubble-animation p {
          transform: translateX(-5000px);
        }

        .scale-on-hover:hover {
          transform: scale(1.1);
        }
        .animClass {
          -webkit-animation: scale-in-center 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: scale-in-center 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation-delay: 6.7s;
        }
        @-webkit-keyframes scale-in-center {
          0% {
            -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
          }
          100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
          }
        }
        @keyframes scale-in-center {
          0% {
            -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
          }
          100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
          }
        }
        .hover-arrow:hover {
          transform: scale(1.05);
          transition: all 0.5s;
        }
        .hover-arrow:hover svg {
          transform: rotate(360deg);
          transition: all 0.5s;
        }
        * {
          transition: all 0.5s;
        }
        .my-shadow {
          box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.15);
        }

        .target-fix {
          display: block;
          top: 0;
          left: 0;
          position: fixed;
        }
        .accordion {
          position: relative;
        }
        .scale-y-1 {
          max-height: auto;
          overflow-y: auto;
          overflow-x: auto;
          transition: max-height 0.3s;
          scroll-behavior: smooth;
          position: static;
        }
        .scale-y-0 {
          max-height: 0;
          overflow-y: hidden;
          overflow-x: auto;
          -webkit-transition: max-height 0.3s;
          transition: max-height 0.3s;
        }
        .slick-list {
          position: relative;
          left: -10px;
        }
        .shadow-new {
          box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.15);
        }
        .bg-review {
          background-size: cover !important;
          background-position: bottom !important;
          height: 320px;
        }
        .slick-track {
          padding: 5px 0;
        }
        .review-1 {
          background: url('https://weprodigi.com/uploads/images/home-page/reviewbg1.jpeg');
        }
        .review-2 {
          background: url('https://weprodigi.com/uploads/images/home-page/reviewbg2.png');
        }
        .review-3 {
          background: url('https://weprodigi.com/uploads/images/home-page/reviewbg3.png');
        }
        .review-4 {
          background: url('https://weprodigi.com/uploads/images/home-page/reviewbg4.png');
        }
        .review-5 {
          background: url('https://weprodigi.com/uploads/images/home-page/reviewbg5.png');
        }
        @media screen and (max-width: 768px) {
          .bg-review {
            /*// background:unset;*/
            height: unset;
          }

          .process-slider .slick-track {
            display: flex;
          }
          .process-slider .slick-slide {
            height: inherit !important;
          }
          .process-slider .slick-slide > div {
            height: 100%;
          }
        }
        .pause {
          display: none !important;
        }

        .for-hover:hover .paused.pause {
          display: block !important;
        }
        .slick-list:first-of-type,
        for-hover:first-of-type {
          padding-left: 0 !important;
        }
        .slick-slide {
          outline: none;
        }
        .slick-slide div {
          outline: none;
        }
        .slick-slide:focus,
        .slick-slide a {
          outline: none;
        }
        @media screen and (max-width: 1024px) {
          .for-hover:hover .paused.pause {
            display: none !important;
          }
        }
        @media screen and (max-width: 768px) {
          img.float-right {
            width: 129px;
          }
          .bg-dark {
            background-image: url('https://weprodigi.com/uploads/images/home-page/dark-BG_mobile4.png');
          }
        }
        .testimonials {
          top: calc(100% - 360px);
          transform: rotate(-90deg) translate(0px, -290px);
          font-weight: 700;
          font-size: 53px;
          line-height: 65px;
          letter-spacing: 0.12em;
          color: rgba(38, 38, 40, 0.08);
        }
        .cases {
          left: -130px;
          top: 120px;
          transform: rotate(270deg);
          font-weight: 700;
          font-size: 53px;
          line-height: 65px;
          letter-spacing: 0.12em;
          color: rgba(38, 38, 40, 0.08);
        }

        [id*='open-accordion'],
        [id*='close-accordion'] {
          background: #00a486;
          border-bottom: 1px solid #fff;
          line-height: 40px;
          height: 40px;
          display: block;
          margin: 0 auto;
          position: relative;
          width: 99%;
        }

        [id*='close-accordion'] {
          display: none;
        }

        .accordion a {
          color: #fff;
          font-size: 1.25em;
          font-weight: normal;
          padding-left: 2%;
          text-decoration: none;
          text-shadow: none;
        }

        [id*='open-accordion']:after,
        [id*='close-accordion']:after {
          content: '';
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid rgba(255, 255, 255, 0.6);
          position: absolute;
          right: 5px;
          top: 15px;
          z-index: 999;
          transform: rotate(-90deg);
          -webkit-transform: rotate(-90deg);
        }

        .target-fix {
          display: block;
          top: 0;
          left: 0;
          position: fixed;
        }

        .accordion-content {
          background: #fff;
          height: 0;
          margin: -1px auto 0;
          padding: 0 2.5%;
          position: relative;
          overflow: hidden;
          width: 90%;
          transition: all 0.1s ease;
          -webkit-transition: all 0.1s ease;
          -moz-transition: all 0.1s ease;
        }

        .accordion span:target ~ .accordion-content {
          display: block;
          height: auto;
          padding-bottom: 25px;
          padding-top: 10px;
        }

        .accordion span:target ~ [id*='close-accordion'] {
          display: block;
        }

        .accordion span:target ~ [id*='open-accordion'] {
          display: none;
        }

        .accordion span:target ~ [id*='close-accordion']:after {
          border-top: 10px solid #333;
          transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
        }
        .img-24 {
          height: 24px;
        }
        .container-lg {
          max-width: 1200px;
          width: 100%;
          margin: 0 auto;
        }
        .hover-svg:hover span {
          color: #670f7a !important;
        }
        .hover-svg:hover svg {
          transform: translate(10px, 0px);
        }
      `}</style>
      <div className="relative w-full  h-650 md:h-820 head-video   overflow-hidden">
        <video width="100%" height="100%" autoPlay loop muted playsInline preload="none" id="header-11" className="absolute header-mp4">
          <source src="https://weprodigi.com//uploads/images/home-page/Header_Desktop_1.mp4" type="video/mp4" />
          {/*<source src='https://weprodigi.com//uploads/images/home-page/Header_Desktop_11.mp4' type="video/mp4"/>*/}
        </video>
        <div className="absolute left-0 md:left-100 lg:left-50 h-full justify-center top-auto hidden lg:flex flex-col  z-2">
          <a
            className="inline-block w-24 h-24 icon-hover"
            target="_blank"
            rel="noopener noreferrer nofollow"
            ariaLabel="Facebook"
            href="https://www.facebook.com/weprodigi/"
          >
            <img src="https://weprodigi.com/images/icons/home/fb.svg" width={24} height={24} layout="intrinsic" className={'img-24'} alt="facebook" />
          </a>
          <a
            className="inline-block w-24 h-24 icon-hover mt-24"
            target="_blank"
            rel="noopener noreferrer nofollow"
            ariaLabel="Behance"
            href="https://www.behance.net/weprodigiagency"
          >
            <img src="https://weprodigi.com/images/icons/home/behance.svg" width={24} height={24} layout="intrinsic" alt="behance" />
          </a>
          <a
            className="inline-block w-24 h-24 icon-hover mt-24"
            target="_blank"
            rel="noopener noreferrer nofollow"
            ariaLabel="Instagram"
            href="https://www.instagram.com/weprodigi_agency/"
          >
            <img src="https://weprodigi.com/images/icons/home/insta.svg" width={24} height={24} layout="intrinsic" alt="instagram" />
          </a>
          <a
            className="inline-block w-24 h-24 icon-hover mt-24"
            target="_blank"
            rel="noopener noreferrer nofollow"
            ariaLabel="Linkedin"
            href="https://www.linkedin.com/company/pro.digi/"
          >
            <img src="https://weprodigi.com/images/icons/home/linkedin.svg" width={24} height={24} layout="intrinsic" alt="linkedin" />
          </a>
          <a
            className="inline-block w-24 h-24 icon-hover mt-24"
            target="_blank"
            rel="noopener noreferrer nofollow"
            ariaLabel="Youtube"
            href="https://www.youtube.com/channel/UC6mkepDFKswxQWS76hgOMBg"
          >
            <img src="https://weprodigi.com/images/icons/home/youtube.svg" width={24} height={24} layout="intrinsic" alt="youtube" />
          </a>
          <a
            className="inline-block w-24 h-24 icon-hover mt-24"
            target="_blank"
            rel="noopener noreferrer nofollow"
            ariaLabel="Twitter"
            href="https://twitter.com/WeProdigi"
          >
            <img src="https://weprodigi.com/images/icons/home/twitter.svg" width={24} height={24} layout="intrinsic" alt="twitter" />
          </a>
        </div>
        <div className="container h-full relative z-2 ">
          <div className="relative h-500 md:h-full  flex flex-col flex-wrap justify-center    ">
            <h1 className={'text-36 md:text-60  leading-37 md:leading-73 text-white font-bolder'}>Full Service Digital Marketing Agency</h1>
            <p className={'text-green text-30 md:text-48 font-400'}>That Brings Results</p>
            <button
              className={'hidden md:block py-5 mt-24 hover:bg-white hover:text-black  rounded-5 border-1 border-white text-white w-200 text-center'}
              onClick={() => {
                setShowModal(true);
                setHidden();
              }}
            >
              Get a Proposal
            </button>
            <a
              href={'/proposals/get-a-proposal'}
              className={'blcok md:hidden py-5 mt-24 hover:bg-white hover:text-black  rounded-5 border-1 border-white text-white w-200 text-center'}
            >
              Get a Proposal
            </a>
          </div>
        </div>
        <div className="hidden md:block container-lg relative z-2 w-full ">
          <ul className="absolute bottom-0 text-white flex h-45 mb-0 list-none flex-wrap justify-between  w-full flex-row">
            <li className={` h-45 cursor-pointer flex-auto text-center`}>
              <div
                className={
                  'text-center text-20  justify-center  border-gray-tab items-center h-45 flex w-full  leading-normal ' +
                  (tab === 1 ? 'text-black bg-white rounded-t-5 font-bolder ' : 'text-white' + ' bg-transparent ') +
                  (tab === 1 || tab === 2 ? '' : 'border-r-1')
                }
                onClick={e => {
                  e.preventDefault();
                  setTab(1);
                }}
                data-toggle="tab"
                role="tablist"
              >
                Paid Advertising
              </div>
            </li>
            <li className={` h-45 cursor-pointer flex-auto text-center`}>
              <div
                className={
                  'text-center text-20  justify-center  border-gray-tab items-center h-45 flex w-full  leading-normal ' +
                  (tab === 2 ? 'text-black bg-white  rounded-t-5 font-bolder ' : 'text-white' + ' bg-transparent ') +
                  (tab === 2 || tab === 3 ? '' : ' border-r-1 ')
                }
                onClick={e => {
                  e.preventDefault();
                  setTab(2);
                }}
                data-toggle="tab"
                role="tablist"
              >
                SMM
              </div>
            </li>
            <li className={` h-45 cursor-pointer flex-auto text-center`}>
              <div
                className={
                  'text-center text-20  justify-center  border-gray-tab items-center h-45 flex w-full  leading-normal ' +
                  (tab === 3 ? 'text-black bg-white rounded-t-5 font-bolder ' : 'text-white' + ' bg-transparent ') +
                  (tab === 3 || tab === 4 ? '' : ' border-r-1 ')
                }
                onClick={e => {
                  e.preventDefault();
                  setTab(3);
                }}
                data-toggle="tab"
                role="tablist"
              >
                Video Production
              </div>
            </li>
            <li className={` h-45 cursor-pointer flex-auto text-center`}>
              <div
                className={
                  'text-center text-20 justify-center  border-gray-tab items-center h-45 flex w-full  leading-normal ' +
                  (tab === 4 ? 'text-black bg-white rounded-t-5  font-bolder ' : 'text-white' + ' bg-transparent') +
                  (tab === 4 || tab === 5 ? '' : ' border-r-1 ')
                }
                onClick={e => {
                  e.preventDefault();
                  setTab(4);
                }}
                data-toggle="tab"
                role="tablist"
              >
                Web Development
              </div>
            </li>
            <li className={` h-45 cursor-pointer flex-auto text-center`}>
              <div
                className={
                  'text-center text-20 justify-center border-gray-tab items-center h-45 flex w-full  leading-normal ' +
                  (tab === 5 ? 'text-black bg-white rounded-t-5  font-bolder ' : 'text-white' + ' bg-transparent')
                }
                onClick={e => {
                  e.preventDefault();
                  setTab(5);
                }}
                data-toggle="tab"
                role="tablist"
              >
                Design
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className={'bg-white '}>
        <div id={'paid-ads-tab'} className="container cursor-pointer">
          <div
            className={`flex md:hidden justify-between  items-center h-60 text-gray-slider  border-b-1  ${
              tab != 1 ? ' border-gray-slider ' : ' border-white '
            } `}
            onClick={() => {
              setTab(tab == 1 ? 0 : 1);
            }}
          >
            <span className={'text-20 leading-24 capitalize text-black'}>Paid Advertising</span>
            {tab == 1 ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
              </svg>
            )}
          </div>
        </div>
        {
          <div className={`${tab == 1 ? ' scale-y-1 ' : ' scale-y-0 '} height-transition bg-white md:bg-white`}>
            <div className={'tab-part container pb-0 md:pb-20'}>
              <div className={'pt-24'}>
                <img
                  className="float-right"
                  src="https://weprodigi.com/uploads/images/home-page/ppc-tab.jpeg"
                  width={263}
                  height={300}
                  alt={'ppc-tab'}
                />
                <h2 className={'text-black text-16 md:text-48 font-bolder max-w-650 leading-24 md:leading-59'}>
                  How can we help your business succeed with paid advertising?
                </h2>
                <p className={'mt-16 text-black font-400 text-16 leading-24 hidden md:block'}>
                  If you are in the growth stage of your small or medium-sized business and want to drive more traffic to your website, generate more
                  leads, increase conversion rates, and improve customer engagement and online sales, paid ads services are so far the most effective
                  and practical way to get results in all the touchpoints. Running paid ads services with us means working with the{' '}
                  <a href="https://partnersdirectory.withgoogle.com/partners/2472484045" target={'_blank'} className={'text-purple font-medium'}>
                    {' '}
                    Google Partner agency{' '}
                  </a>
                  , a team of certified specialists with paid ads skills, expertise, and certifications, as a guarantee of maximizing your campaign
                  success. Having unique access to exclusive webinars and being among the first to acquire the latest trends, our experts are always
                  “on the wave” to create efficient campaigns. You will have a dedicated account manager ensuring we are on the same page with the
                  same goals and vision. The professional team-as-a-service approach aims to save your time and build campaigns within the shortest
                  deadline while saving your time and budget. Finally, to show you the results of our efforts, we provide regular and 100% transparent
                  reporting to display the ad campaign’s performance, achieved results, and how much of your investment is converted into revenue.
                  Right after collaborating with our team and launching the first ad campaign, you will get the immediate outcome and trackable
                  results.
                </p>
                <div className={'block md:hidden'}>
                  <ViewMorePPC from={'homepage'} ln={55}>
                    If you are in the growth stage of your small or medium-sized business and want to drive more traffic to your website, generate
                    more leads, increase conversion rates, and improve customer engagement and online sales, paid ads services are so far the most
                    effective and practical way to get results in all the touchpoints. Running paid ads services with us means working with the Google
                    Partner agency, a team of certified specialists with paid ads skills, expertise, and certifications, as a guarantee of maximizing
                    your campaign success. Having unique access to exclusive webinars and being among the first to acquire the latest trends, our
                    experts are always “on the wave” to create efficient campaigns. You will have a dedicated account manager ensuring we are on the
                    same page with the same goals and vision. The professional team-as-a-service approach aims to save your time and build campaigns
                    within the shortest deadline while saving your time and budget. Finally, to show you the results of our efforts, we provide
                    regular and 100% transparent reporting to display the ad campaign’s performance, achieved results, and how much of your investment
                    is converted into revenue. Right after collaborating with our team and launching the first ad campaign, you will get the immediate
                    outcome and trackable results.
                  </ViewMorePPC>
                </div>
              </div>
            </div>
            <div className="bg-review review-1 mt-20 md:mt-0">
              <div className={'container mt-0 md:mt-0 h-full flex flex-wrap md:flex-nowrap justify-between items-center pb-30 md:pb-0'}>
                <div className={'w-full md:w-auto mt-0 md:mt-0  py-24'}>
                  <p className={'text-white md:text-white text-20 md:text-32 leading-24 md:leading-39 font-400 max-w-450'}>
                    We've driven <span className={'text-green font-bolder'}>658110 leads</span> for our clients through paid ads services.
                  </p>

                  <p className="text-white md:text-white font-normal-700 text-20 md:text-32 leading-24 md:leading-39">
                    Discover how we can help your business
                  </p>
                  {/*<button className={'w-full md:w-auto mt-24 text-white bg-green rounded-5 px-23 py-5'}>Send me a proposal</button>*/}
                  <div className={'w-full hidden md:flex'}>
                    <a
                      href={'https://weprodigi.com/paid-advertising-services'}
                      className="text-center capitalize hover:bg-green  block w-full md:w-auto mt-24 text-white bg-purple rounded-5 px-23 py-5"
                    >
                      see full service
                    </a>
                    <button
                      className="hidden ml-20  hover:bg-purple md:block w-full md:w-auto mt-24 text-white bg-green rounded-5 px-23 py-5"
                      onClick={() => {
                        setShowModalPPC(true);
                        setHidden();
                      }}
                    >
                      Get Pricing
                    </button>
                    <a
                      className="block  hover:bg-purple md:hidden text-center w-full md:w-auto mt-24 text-white bg-green rounded-5 px-23 py-5"
                      href={'/paid-advertising-services/get-proposal'}
                    >
                      Get Pricing
                    </a>
                  </div>
                </div>
                <div className={' w-full md:w-auto  md:block pl-0 md:pl-40'}>
                  <p className="text-white font-normal-700 text-20 mb-10 md:mb-0 leading-28 md:leading-39">What our paid ads clients say</p>
                  <div className="bg-white shadow-lg min-h-280 md:min-h-250  card-rounded py-25 px-14 flex justify-start items-start relative">
                    <img
                      src="https://weprodigi.com/uploads/images/home-page/Formatquote.svg"
                      className={'-mt-12'}
                      width={55}
                      height={55}
                      alt="format quote"
                    />
                    <div className={'relative max-w-320'}>
                      <p className={'text-black text-16 leading-20 font-400'}>
                        WeProdigi Agency brought 1250 leads and 250 conversions in just three months, improving the ROI. They effectively liaised with
                        the internal team and showcased a keen understanding ...
                      </p>
                      <p className={'text-purple text-16 leading-20 font-bolder mt-16'}>David Joness, CEO</p>
                      <p className="text-black uppercase text-16 leading-20  mt-8">Aventura Travel</p>
                    </div>
                    <a
                      aria-label={'view more'}
                      href="https://clutch.co/profile/weprodigi-agency"
                      target={'_blank'}
                      className={'text-purple text16 font-bolder flex items-center absolute bottom-15 hover-arrow right-30'}
                    >
                      More
                      <svg xmlns="http://www.w3.org/2000/svg" width={18} viewBox="0 0 20 20" fill="currentColor">
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </a>
                  </div>
                  <div className={'w-full flex md:hidden'}>
                    <a
                      href={'https://weprodigi.com/paid-advertising-services'}
                      className="text-center text-16 capitalize hover:bg-green  block w-full md:w-auto mt-24 text-white bg-purple rounded-5  px-0 md:px-23 py-5"
                    >
                      see full service
                    </a>
                    <a
                      className="block  hover:bg-purple md:hidden text-center text-16 w-full md:w-auto mt-24 ml-20 text-white bg-green rounded-5 px-none md:px-23 py-5"
                      href={'/paid-advertising-services/get-proposal'}
                    >
                      Get Pricing
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        <div id={'smm-tab'} className="container cursor-pointer">
          <div
            className={`flex md:hidden justify-between  items-center h-60 text-gray-slider  border-b-1  ${
              tab != 2 ? ' border-gray-slider ' : ' border-white '
            } `}
            onClick={() => {
              setTab(tab == 2 ? 0 : 2);
            }}
          >
            <span className={'text-20 leading-24 capitalize text-black'}>SMM</span>
            {tab == 2 ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
              </svg>
            )}
          </div>
        </div>
        {tab == 2 && (
          <div className={`${tab == 2 ? ' scale-y-1 ' : ' scale-y-0 '} height-transition bg-white md:bg-white`}>
            <div className={'tab-part container pb-0 md:pb-20'}>
              <div className={'pt-24'}>
                <img width={240} className="float-right" src="https://weprodigi.com/uploads/images/home-page/pencil.png" alt="pencil" />
                <h2 className={'text-black text-16 md:text-48 font-bolder max-w-650 leading-24 md:leading-59'}>
                  How can we help your business succeed with SMM Services?
                </h2>
                <p className={'mt-16 text-black font-400 text-16 leading-24 hidden md:block'}>
                  Whether your business is a B2B or B2C company, running professional social media channels is a must as a crucial part of your
                  business presence. Social Media platforms are used to boost customer loyalty, increase brand awareness, provide an omnichannel
                  connection with your target customers, raise customer satisfaction, and build brand trust. Having advanced social media skills is
                  not enough to produce significant results for businesses. To be an expert in social media marketing, one must, first and foremost,
                  possess current marketing skills and be able to generate relevant content by understanding customer needs. Only a professional with
                  relevant expertise can maintain the brand’s successful social media presence and create connections with customers. Our social media
                  marketing specialists are
                  <a
                    href="https://www.credly.com/badges/5a49bdca-665c-4087-9428-37cad0ec1938/public_url"
                    target={'_blank'}
                    className={'text-purple font-medium'}
                  >
                    {' '}
                    FB official certified professionals{' '}
                  </a>
                  with relevant skills, expertise, and the latest tools for social media marketing. We have worked with over
                  <a href="https://weprodigi.com/industries" className={'text-purple font-medium'}>
                    {' '}
                    90 SMM partners in 12 industries{' '}
                  </a>{' '}
                  and received countless testimonials and feedback on
                  <a
                    aria-label={'view more'}
                    href="https://clutch.co/profile/weprodigi-agency#summary"
                    target={'_blank'}
                    className={'text-purple font-medium'}
                  >
                    {' '}
                    independent review sites{' '}
                  </a>
                  as a testament to our expertise. For added value, we have an in-house production team generating unique videos and creative assets
                  that can guarantee your brand’s competitive advantage on social media. When investing in social media marketing, you should take
                  into account that social media marketing service is not a direct sales tool that promotes immediate results, unlike paid ads
                  services. Instead, they have a long-term success focused on raising brand awareness and establishing a liable connection between
                  your company and audience. You will have the return on investments in SMM in about two months, but the results will exceed your
                  expectations.
                </p>
                <div className={'block md:hidden'}>
                  <ViewMorePPC from={'homepage'} ln={55}>
                    Whether your business is a B2B or B2C company, running professional social media channels is a must as a crucial part of your
                    business presence. Social Media platforms are used to boost customer loyalty, increase brand awareness, provide an omnichannel
                    connection with your target customers, raise customer satisfaction, and build brand trust. Having advanced social media skills is
                    not enough to produce significant results for businesses. To be an expert in social media marketing, one must, first and foremost,
                    possess current marketing skills and be able to generate relevant content by understanding customer needs. Only a professional
                    with relevant expertise can maintain the brand’s successful social media presence and create connections with customers. Our
                    social media marketing specialists are
                    <a
                      href="https://www.credly.com/badges/5a49bdca-665c-4087-9428-37cad0ec1938/public_url"
                      target={'_blank'}
                      className={'text-purple font-medium'}
                    >
                      {' '}
                      FB official certified professionals
                    </a>
                    with relevant skills, expertise, and the latest tools for social media marketing. We have worked with over
                    <a aria-label={'industries'} href="https://weprodigi.com/industries" className={'text-purple font-medium'}>
                      {' '}
                      90 SMM partners in 12 industries{' '}
                    </a>{' '}
                    and received countless testimonials and feedback on
                    <a
                      aria-label={'view more'}
                      href="https://clutch.co/profile/weprodigi-agency#summary"
                      target={'_blank'}
                      className={'text-purple font-medium'}
                    >
                      {' '}
                      independent review sites{' '}
                    </a>
                    as a testament to our expertise. For added value, we have an in-house production team generating unique videos and creative assets
                    that can guarantee your brand’s competitive advantage on social media. When investing in social media marketing, you should take
                    into account that social media marketing service is not a direct sales tool that promotes immediate results, unlike paid ads
                    services. Instead, they have a long-term success focused on raising brand awareness and establishing a liable connection between
                    your company and audience. You will have the return on investments in SMM in about two months, but the results will exceed your
                    expectations.
                  </ViewMorePPC>
                </div>
              </div>
            </div>
            <div className="bg-review review-2 mt-20 md:mt-0">
              <div className={'container mt-0 md:mt-0 h-full flex flex-wrap md:flex-nowrap justify-between items-center pb-30 md:pb-0'}>
                <div className={'w-full md:w-auto mt-0 md:mt-0  py-24'}>
                  <p className={'text-white md:text-white text-20 md:text-32 leading-24 md:leading-39 font-400 max-w-650'}>
                    We've driven <span className={'text-green font-bolder'}>19,570,000 user engagements</span> for our clients through SMM services.
                  </p>
                  <p className="text-white md:text-white font-normal-700 text-20 md:text-32 leading-24 md:leading-39">
                    Discover how we can help your business
                  </p>
                  {/*<button className={'w-full md:w-auto mt-24 text-white bg-green rounded-5 px-23 py-5'}>Send me a proposal</button>*/}
                  <div className={'w-full hidden md:flex'}>
                    <a
                      href={'https://weprodigi.com/social-media-marketing'}
                      className="text-center capitalize hover:bg-green  block w-full md:w-auto mt-24 text-white bg-purple rounded-5 px-23 py-5"
                    >
                      see full service
                    </a>
                    <button
                      className="hidden ml-20  hover:bg-purple md:block w-full md:w-auto mt-24 text-white bg-green rounded-5 px-23 py-5"
                      onClick={() => {
                        setShowModalSMM(true);
                        setHidden();
                      }}
                    >
                      Get Pricing
                    </button>
                    <a
                      className="block  hover:bg-purple md:hidden text-center w-full md:w-auto mt-24 text-white bg-green rounded-5 px-23 py-5"
                      href={'/social-media-marketing/proposals/smm-pricing'}
                    >
                      Get Pricing
                    </a>
                  </div>
                </div>
                <div className={'w-full md:w-auto  md:block pl-0 md:pl-40'}>
                  <p className="text-white font-normal-700 text-20 mb-10 md:mb-0 leading-28 md:leading-39">What our SMM clients say</p>
                  <div className="bg-white shadow-lg min-h-280 md:min-h-250   card-rounded py-25 px-14 flex justify-start items-start relative">
                    <img
                      src="https://weprodigi.com/uploads/images/home-page/Formatquote.svg"
                      className={'-mt-12'}
                      width={55}
                      height={55}
                      alt="format quote"
                    />
                    <div className={'relative max-w-320'}>
                      <p className={'text-black text-16 leading-20 font-400'}>
                        We had easy communication together, they were always available. We always knew at which stage we were at all times. Our social
                        media pages’ engagement had grown over 2.5 -3 times.
                      </p>
                      <p className={'text-purple text-16 leading-20 font-bolder mt-16'}>Johanna Holland, CEO</p>
                      <p className="text-black uppercase text-16 leading-20  mt-8">Health Lines</p>
                    </div>
                    <a
                      aria-label={'view more'}
                      href="https://clutch.co/profile/weprodigi-agency?page=1&project_cost=&review_type=&service_provided=&sort_by=date_DESC#review-1895941"
                      target={'_blank'}
                      className={'text-purple text16 font-bolder flex items-center absolute bottom-15 hover-arrow right-30'}
                    >
                      More
                      <svg xmlns="http://www.w3.org/2000/svg" width={18} viewBox="0 0 20 20" fill="currentColor">
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </a>
                  </div>
                  <div className={'w-full flex md:hidden'}>
                    <a
                      href={'https://weprodigi.com/social-media-marketing'}
                      className="text-center text-16 capitalize hover:bg-green  block w-full md:w-auto mt-24 text-white bg-purple rounded-5  px-0 md:px-23 py-5"
                    >
                      see full service
                    </a>

                    <a
                      className="block  hover:bg-purple md:hidden text-center text-16 w-full md:w-auto mt-24 ml-20 text-white bg-green rounded-5 px-none md:px-23 py-5"
                      href={'/social-media-marketing/proposals/smm-pricing'}
                    >
                      Get Pricing
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div id={'video-tab'} className="container cursor-pointer">
          <div
            className={`flex md:hidden justify-between  items-center h-60 text-gray-slider  border-b-1  ${
              tab != 3 ? ' border-gray-slider ' : ' border-white '
            } `}
            onClick={() => {
              setTab(tab == 3 ? 0 : 3);
            }}
          >
            <span className={'text-20 leading-24 capitalize text-black'}>Video Production</span>
            {tab == 3 ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
              </svg>
            )}
          </div>
        </div>
        {tab == 3 && (
          <div className={`${tab == 3 ? ' scale-y-1 ' : ' scale-y-0 '} height-transition bg-white md:bg-white`}>
            <div className={'tab-part container pb-0 md:pb-20'}>
              <div className={'pt-24'}>
                <img width={284} className="float-right" src="https://weprodigi.com/uploads/images/home-page/reel.png" alt={'reel'} />
                <h2 className={'text-black text-16 md:text-48 font-bolder max-w-650 leading-24 md:leading-59'}>
                  How can we help your business succeed with Video Production?
                </h2>
                <p className={'mt-16 text-black font-400 text-16 leading-24 hidden md:block'}>
                  Through our video production services, we assist businesses from multiple industries in meeting their business needs, including
                  marketing, sales, education, human resource management, customer support, and non-profit organizations involved in education,
                  entertainment, and other fields. Specialized in producing{' '}
                  <a href="https://weprodigi.com/video-production/explainer-videos" className={'text-purple font-medium'}>
                    Explainer
                  </a>
                  , How-tos, Educational, Commercial, Product Videos, Social Media Videos, Webinars, and Vlog Videos, our in-house video production
                  team can deliver any video solution for your marketing campaigns. Our production services include live-action videos (shooting,
                  editing, and mixing media) and animation-style videos with 2D and 3D, Whiteboard, and Motion Infographics animation. Approaching the
                  video production service with great responsibility, we cover all the processes with in-house specialists, thus establishing a
                  professional environment for service delivery. We own an exclusive
                  <a href="https://weprodigi.com/voiceover/0XcrYB" className={'text-purple font-medium'}>
                    {' '}
                    voice-over library{' '}
                  </a>{' '}
                  and handle its production, ensuring you are free of any worries. Our video production team closely communicates with digital
                  marketing specialists throughout the production process to make a targeted video that will go viral on all platforms, including
                  social media channels, landing pages, and blogs. To make you maximum benefit from our partnership, we will give you unlimited and
                  absolutely free revisions at every production stage. Finally, with expertise in various industries, from engineering to healthcare,
                  our scriptwriters deep dive into business specifics and create scripts without wasting a second of your valuable time. Our videos
                  are used for multiple purposes and various digital marketing channels by our clients. Their efficiency is proven by over 120 client
                  testimonials sharing their experience and goals reached with tailor-made videos.
                </p>
                <div className={'block md:hidden'}>
                  <ViewMorePPC from={'homepage'} ln={55}>
                    Through our video production services, we assist businesses from multiple industries in meeting their business needs, including
                    marketing, sales, education, human resource management, customer support, and non-profit organizations involved in education,
                    entertainment, and other fields. Specialized in producing Explainer, How-tos, Educational, Commercial, Product Videos, Social
                    Media Videos, Webinars, and Vlog Videos, our in-house video production team can deliver any video solution for your marketing
                    campaigns. Our production services include live-action videos (shooting, editing, and mixing media) and animation-style videos
                    with 2D and 3D, Whiteboard, and Motion Infographics animation. Approaching the video production service with great responsibility,
                    we cover all the processes with in-house specialists, thus establishing a professional environment for service delivery. We own an
                    exclusive
                    <a href="https://weprodigi.com/voiceover/0XcrYB" className={'text-purple font-medium'}>
                      {' '}
                      voice-over library{' '}
                    </a>{' '}
                    and handle its production, ensuring you are free of any worries. Our video production team closely communicates with digital
                    marketing specialists throughout the production process to make a targeted video that will go viral on all platforms, including
                    social media channels, landing pages, and blogs. To make you maximum benefit from our partnership, we will give you unlimited and
                    absolutely free revisions at every production stage. Finally, with expertise in various industries, from engineering to
                    healthcare, our scriptwriters deep dive into business specifics and create scripts without wasting a second of your valuable time.
                    Our videos are used for multiple purposes and various digital marketing channels by our clients. Their efficiency is proven by
                    over 120 client testimonials sharing their experience and goals reached with tailor-made videos.
                  </ViewMorePPC>
                </div>
              </div>
            </div>
            <div className="bg-review review-3 mt-20 md:mt-0">
              <div className={'container mt-0 md:mt-0 h-full flex flex-wrap md:flex-nowrap justify-between items-center pb-30 md:pb-0'}>
                <div className={'w-full md:w-auto mt-0 md:mt-0  py-24'}>
                  <p className={'text-white md:text-white text-20 md:text-32 leading-24 md:leading-39 font-400 max-w-650'}>
                    We've driven <span className={'text-green font-bolder'}>86,300,000 video views</span> for our clients through video production
                    services.
                  </p>
                  <p className="text-white md:text-white font-normal-700 text-20 md:text-32 leading-24 md:leading-39">
                    Discover how we can help your business
                  </p>
                  {/*<button className={'w-full md:w-auto mt-24 text-white bg-green rounded-5 px-23 py-5'}>Send me a proposal</button>*/}
                  <div className={'w-full hidden md:flex'}>
                    <a
                      href={'https://weprodigi.com/video-production'}
                      className="text-center capitalize hover:bg-green  block w-full md:w-auto mt-24 text-white bg-purple rounded-5 px-23 py-5"
                    >
                      see full service
                    </a>
                    <button
                      className="hidden ml-20  hover:bg-purple md:block w-full md:w-auto mt-24 text-white bg-green rounded-5 px-23 py-5"
                      onClick={() => {
                        setShowPricingModal(true);
                        setHidden();
                      }}
                    >
                      Get Pricing
                    </button>
                    <a
                      className="block  hover:bg-purple md:hidden text-center w-full md:w-auto mt-24 text-white bg-green rounded-5 px-23 py-5"
                      href={'/video-production/get-pricing'}
                    >
                      Get Pricing
                    </a>
                  </div>
                </div>
                <div className={'w-full md:w-auto  md:block pl-0 md:pl-40'}>
                  <p className="text-white font-normal-700 text-18 md:text-18 leading-28 mb-10 md:mb-0 md:leading-39">
                    What our video production clients say
                  </p>
                  <div className="bg-white shadow-lg min-h-280 md:min-h-250   card-rounded py-25 px-14 flex justify-start items-start relative">
                    <img
                      src="https://weprodigi.com/uploads/images/home-page/Formatquote.svg"
                      className={'-mt-12'}
                      width={55}
                      height={55}
                      alt="format quote"
                    />
                    <div className={'relative max-w-320'}>
                      <p className={'text-black text-16 leading-20 font-400'}>
                        Their creativity and passion for the project is impressive. Their ability to work to tight schedules and meet deadlines. Their
                        ability to think outside the box especially during the COVID-19 restrictions .
                      </p>
                      <p className={'text-purple text-16 leading-20 font-bolder mt-16'}>Johnson Samuyiwa, Director </p>
                      <p className="text-black  text-16 leading-20  mt-8">SelectTVFormats LTD</p>
                    </div>
                    <a
                      aria-label={'view more'}
                      href="https://clutch.co/profile/weprodigi-agency?page=3&project_cost=&review_type=&service_provided=&sort_by=date_DESC#review-1651937"
                      target={'_blank'}
                      className={'text-purple text16 font-bolder flex items-center absolute bottom-15 hover-arrow right-30'}
                    >
                      More
                      <svg xmlns="http://www.w3.org/2000/svg" width={18} viewBox="0 0 20 20" fill="currentColor">
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </a>
                  </div>
                  <div className={'w-full flex md:hidden'}>
                    <a
                      href={'https://weprodigi.com/video-production'}
                      className="text-center text-16 capitalize hover:bg-green  block w-full md:w-auto mt-24 text-white bg-purple rounded-5  px-0 md:px-23 py-5"
                    >
                      see full service
                    </a>

                    <a
                      className="block  hover:bg-purple md:hidden text-center text-16 w-full md:w-auto mt-24 ml-20 text-white bg-green rounded-5 px-none md:px-23 py-5"
                      href={'/video-production/get-pricing'}
                    >
                      Get Pricing
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div id={'web-tab'} className="container cursor-pointer">
          <div
            className={`flex md:hidden justify-between  items-center h-60 text-gray-slider  border-b-1  ${
              tab != 4 ? ' border-gray-slider ' : ' border-white '
            } `}
            onClick={() => {
              setTab(tab == 4 ? 0 : 4);
            }}
          >
            <span className={'text-20 leading-24 capitalize text-black'}>Web Development</span>
            {tab == 4 ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
              </svg>
            )}
          </div>
        </div>
        {tab == 4 && (
          <div className={`${tab == 4 ? ' scale-y-1 ' : ' scale-y-0 '} height-transition bg-white md:bg-white`}>
            <div className={'tab-part container pb-0 md:pb-20'}>
              <div className={'pt-24'}>
                <img className="float-right" src="https://weprodigi.com/uploads/images/home-page/webDevelopment.png" alt={'web development'} />
                <h2 className={'text-black text-16 md:text-48 font-bolder max-w-650 leading-24 md:leading-59'}>
                  How can we help your business succeed with Web Development Services?
                </h2>
                <p className={'mt-16 text-black font-400 text-16 leading-24 hidden md:block'}>
                  In the digital environment, the website is a powerful tool to create brand awareness and showcase your products or services to
                  prospective customers, especially in the early stages of business development. A robust start by leveraging all the marketing
                  channels, including a user-friendly website, is the right thing to choose, and we can certainly help you build your professional
                  presence with a website. Our team of engineers, proficient in PHP, ASP, and HTML5, will handle your website’s development and
                  deployment, making it completely responsive for further marketing efforts. With over 10 years of experience in web design and
                  development and a core team of developers working side by side with the marketers, they definitely know how to build a website and
                  stress out the functionality that will later be used to drive traffic and higher engagement and retention rates. At ProDigi, whether
                  you choose a templated page or a website from scratch, every single project is treated with a multi-dimensional approach. To make
                  sure the website is fully functional, every piece is proven by Quality Engineers focusing on page speed and functionality. The
                  success of your website depends on its design, functionality, and powerful SEO optimization to make the website work for your goals.
                  After the deployment, we will step into the second phase attracting visitors to your website through different marketing channels.
                </p>
                <div className={'block md:hidden'}>
                  <ViewMorePPC from={'homepage'} ln={55}>
                    In the digital environment, the website is a powerful tool to create brand awareness and showcase your products or services to
                    prospective customers, especially in the early stages of business development. A robust start by leveraging all the marketing
                    channels, including a user-friendly website, is the right thing to choose, and we can certainly help you build your professional
                    presence with a website. Our team of engineers, proficient in PHP, ASP, and HTML5, will handle your website’s development and
                    deployment, making it completely responsive for further marketing efforts. With over 10 years of experience in web design and
                    development and a core team of developers working side by side with the marketers, they definitely know how to build a website and
                    stress out the functionality that will later be used to drive traffic and higher engagement and retention rates. At ProDigi,
                    whether you choose a templated page or a website from scratch, every single project is treated with a multi-dimensional approach.
                    To make sure the website is fully functional, every piece is proven by Quality Engineers focusing on page speed and functionality.
                    The success of your website depends on its design, functionality, and powerful SEO optimization to make the website work for your
                    goals. After the deployment, we will step into the second phase attracting visitors to your website through different marketing
                    channels.
                  </ViewMorePPC>
                </div>
              </div>
            </div>
            <div className="bg-review review-4 mt-20 md:mt-0">
              <div className={'container mt-0 md:mt-0 h-full flex flex-wrap md:flex-nowrap justify-between items-center pb-30 md:pb-0'}>
                <div className={'w-full md:w-auto mt-0 md:mt-0  py-24'}>
                  <p className={'text-white md:text-white text-20 md:text-32 leading-24 md:leading-39 font-400 max-w-650'}>
                    We've <span className={'text-green font-bolder'}>124 custom websites</span> for our clients through web development services.
                  </p>
                  <p className="text-white md:text-white font-normal-700 text-20 md:text-32 leading-24 md:leading-39">
                    Discover how we can help your business
                  </p>
                  {/*<button className={'w-full md:w-auto mt-24 text-white bg-green rounded-5 px-23 py-5'}>Send me a proposal</button>*/}
                  <div className={'w-full hidden md:flex'}>
                    <button
                      className="hidden   hover:bg-purple md:block w-full md:w-auto mt-24 text-white bg-green rounded-5 px-23 py-5"
                      onClick={() => {
                        setShowOfferModalWeb(true);
                        setHidden();
                      }}
                    >
                      Get Pricing
                    </button>
                    <a
                      className="block  hover:bg-purple md:hidden text-center w-full md:w-auto mt-24 text-white bg-green rounded-5 px-23 py-5"
                      href={'/get-proposal?t=web'}
                    >
                      Get Pricing
                    </a>
                  </div>
                </div>
                <div className={'w-full md:w-auto  md:block pl-0 md:pl-40'}>
                  <p className="text-white font-normal-700 text-18 leading-28 mb-10 md:mb-0 md:leading-39">What our web development clients say</p>
                  <div className="bg-white shadow-lg min-h-280 md:min-h-250   card-rounded py-25 px-14 flex justify-start items-start relative">
                    <img
                      src="https://weprodigi.com/uploads/images/home-page/Formatquote.svg"
                      className={'-mt-12'}
                      width={55}
                      height={55}
                      alt="form quote"
                    />
                    <div className={'relative max-w-320'}>
                      <p className={'text-black text-16 leading-20 font-400'}>
                        What's impressive is their immediate response and making me feel as part of the team that will create an impressive solution.
                        Of course, very unique as well are their extensive talents and professional, punctual responses.
                      </p>
                      <p className={'text-purple text-16 leading-20 font-bolder mt-16'}>Rene Openiano, Founder</p>
                      <p className="text-black uppercase text-16 leading-20  mt-8">Orentrix Inc.</p>
                    </div>
                    <a
                      aria-label={'view more'}
                      href="https://clutch.co/profile/weprodigi-agency?page=3&project_cost=&review_type=&service_provided=&sort_by=date_DESC#review-1651937"
                      target={'_blank'}
                      className={'text-purple text16 font-bolder flex items-center absolute bottom-15 hover-arrow right-30'}
                    >
                      More
                      <svg xmlns="http://www.w3.org/2000/svg" width={18} viewBox="0 0 20 20" fill="currentColor">
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </a>
                  </div>
                  <div className={'w-full flex md:hidden'}>
                    <a
                      className="block  hover:bg-purple md:hidden text-center text-16 w-full md:w-auto mt-24   text-white bg-green rounded-5 px-none md:px-23 py-5"
                      href={'/get-proposal?t=web'}
                    >
                      Get Pricing
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div id={'design-tab'} className="container cursor-pointer">
          <div
            className={'flex md:hidden justify-between  items-center h-60 text-gray-slider border-gray-slider'}
            onClick={() => {
              setTab(tab == 5 ? 0 : 5);
            }}
          >
            <span className={'text-20 leading-24 capitalize text-black'}>Design</span>
            {tab == 5 ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
              </svg>
            )}
          </div>
        </div>
        {tab == 5 && (
          <div className={`${tab == 5 ? ' scale-y-1 ' : ' scale-y-0 '} height-transition bg-white md:bg-white`}>
            <div className={'tab-part container pb-0 md:pb-20 '}>
              <div className={'pt-24'}>
                <img className="float-right" src="https://weprodigi.com/uploads/images/home-page/Design.png" alt={'design'} />
                <h2 className={'text-black text-16 md:text-48 font-bolder max-w-650 leading-24 md:leading-59'}>
                  How can we help your business succeed with Design Services?
                </h2>
                <p className={'mt-16 text-black font-400 text-16 leading-24 hidden md:block'}>
                  With over a decade of design experience, we provide 100% custom design services for online and offline uses. We support small and
                  medium-sized businesses, non-profit organizations, and educational institutions, providing web, print, graphic, and logo design
                  services to fulfill their business needs for marketing, sales, and education initiatives. Focusing on a design that is not only
                  beautiful and catchy but also purpose-driven helps us create targeted and efficient marketing campaigns best fitting your audience
                  and its needs. We leverage our marketing expertise and design to reach your goals and provide maximum engagement. Working with over
                  25 production software (including Adobe Photoshop, Illustrator, Animator, After Effects, InDesign, Figma, Corel Draw, etc.) and
                  having fulfilled projects in more than 30 industries, we are confident in our skills and expertise in delivering top-notch design
                  ideas tailored for your business and your audience needs. While design templates are quick and inexpensive, they are far from being
                  unique and having a value proposition. Our focus is on creating an on-brand design and avoiding templates, saving your time. Be
                  ready to get immediate results with our design services and continue providing a professional presence on digital platforms.
                </p>
                <div className={'block md:hidden'}>
                  <ViewMorePPC from={'homepage'} ln={55}>
                    With over a decade of design experience, we provide 100% custom design services for online and offline uses. We support small and
                    medium-sized businesses, non-profit organizations, and educational institutions, providing web, print, graphic, and logo design
                    services to fulfill their business needs for marketing, sales, and education initiatives. Focusing on a design that is not only
                    beautiful and catchy but also purpose-driven helps us create targeted and efficient marketing campaigns best fitting your audience
                    and its needs. We leverage our marketing expertise and design to reach your goals and provide maximum engagement. Working with
                    over 25 production software (including Adobe Photoshop, Illustrator, Animator, After Effects, InDesign, Figma, Corel Draw, etc.)
                    and having fulfilled projects in more than 30 industries, we are confident in our skills and expertise in delivering top-notch
                    design ideas tailored for your business and your audience needs. While design templates are quick and inexpensive, they are far
                    from being unique and having a value proposition. Our focus is on creating an on-brand design and avoiding templates, saving your
                    time. Be ready to get immediate results with our design services and continue providing a professional presence on digital
                    platforms.
                  </ViewMorePPC>
                </div>
              </div>
            </div>
            <div className="bg-review review-5 mt-20 md:mt-0">
              <div className={'container mt-0 md:mt-0 h-full flex flex-wrap md:flex-nowrap justify-between items-center pb-30 md:pb-0'}>
                <div className={'w-full md:w-auto mt-0 md:mt-0  py-24'}>
                  <p className={'text-white md:text-white text-20 md:text-32 leading-24 md:leading-39 font-400 max-w-650'}>
                    We've <span className={'text-green font-bolder'}> 64,200 creative assets</span> for our clients through our design services.
                  </p>
                  <p className="text-white md:text-white font-normal-700 text-20 md:text-32 leading-24 md:leading-39">
                    Discover how we can help your business
                  </p>
                  {/*<button className={'w-full md:w-auto mt-24 text-white bg-green rounded-5 px-23 py-5'}>Send me a proposal</button>*/}
                  <div className={'w-full hidden md:flex'}>
                    <a
                      href={'https://weprodigi.com/design'}
                      className="text-center capitalize hover:bg-green  block w-full md:w-auto mt-24 text-white bg-purple rounded-5 px-23 py-5"
                    >
                      see full service
                    </a>
                    <button
                      className="hidden ml-20  hover:bg-purple md:block w-full md:w-auto mt-24 text-white bg-green rounded-5 px-23 py-5"
                      onClick={() => {
                        setShowOfferModalDesign(true);
                        setHidden();
                      }}
                    >
                      Get Pricing
                    </button>
                    <a
                      className="block  hover:bg-purple md:hidden text-center w-full md:w-auto mt-24 text-white bg-green rounded-5 px-23 py-5"
                      href={'/get-proposal?t=design'}
                    >
                      Get Pricing
                    </a>
                  </div>
                </div>
                <div className={'w-full md:w-auto  md:block pl-0 md:pl-40'}>
                  <p className="text-white font-normal-700 text-18 mb-10 md:mb-0 leading-28 md:leading-39">What our design services clients say</p>
                  <div className="bg-white shadow-lg min-h-280 md:min-h-250   card-rounded py-25 px-14 flex justify-start items-start relative">
                    <img
                      src="https://weprodigi.com/uploads/images/home-page/Formatquote.svg"
                      className={'-mt-12'}
                      width={55}
                      height={55}
                      alt="form quote"
                    />
                    <div className={'relative max-w-320'}>
                      <p className={'text-black text-16 leading-20 font-400'}>
                        The best part is how fast and easy it is to work with them. We looked through different companies but ProDigi was the best fit
                        for us in all terms.
                      </p>
                      <p className={'text-purple text-16 leading-20 font-bolder mt-16'}>Luis Prieto, Operations Manager</p>
                      <p className="text-black uppercase text-16 leading-20  mt-8">Dispatchional</p>
                    </div>
                    <a
                      aria-label={'view more'}
                      href="https://clutch.co/profile/weprodigi-agency?page=3&project_cost=&review_type=&service_provided=&sort_by=date_DESC#review-1651937"
                      target={'_blank'}
                      className={'text-purple text16 font-bolder flex items-center absolute bottom-15 hover-arrow right-30'}
                    >
                      More
                      <svg xmlns="http://www.w3.org/2000/svg" width={18} viewBox="0 0 20 20" fill="currentColor">
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </a>
                  </div>
                  <div className={'w-full flex md:hidden'}>
                    <a
                      href={'https://weprodigi.com/design'}
                      className="text-center text-16 capitalize hover:bg-green  block w-full md:w-auto mt-24 text-white bg-purple rounded-5  px-0 md:px-23 py-5"
                    >
                      see full service
                    </a>

                    <a
                      className="block  hover:bg-purple md:hidden text-center text-16 w-full md:w-auto mt-24 ml-20 text-white bg-green rounded-5 px-none md:px-23 py-5"
                      href={'/get-proposal?t=design'}
                    >
                      Get Pricing
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="bg-white hidden md:block">
        <div className="container">
          <div className="title font-bolder text-48 pt-24 text-black   uppercase">Discover Our Services</div>
          <div className="flex flex-wrap py-30 pt-20 justify-between">
            <div className=" w-full md:w-half-auto-2">
              <div className=" font-normal text-black text-16 ">
                <ViewMoreWithTags ln={49}>
                  The name WeProdigi is the combination of <span className="text-purple font-bold">We</span> standing for our unity as a huge in–house{' '}
                  <span className="text-purple font-bold">Pro</span>
                  fessional team of 30+ certified digital specialists offering you a wide variety of high quality{' '}
                  <span className="text-purple font-bold">Digi</span>tal services, specifically Digital Marketing and Video Production services of any
                  type and style with 100% customized approach. They are for various industry companies to fulfill your business needs such as
                  marketing, sales, HR, education, project management, customer support, as well as for Non-profit organizations working in the field
                  of education, entertainment, etc. We are fit for any of your requirements and our team has no boundaries offering custom ideas and
                  creative solutions. At WeProdigi, everything is done to save your time, find any information you need easily!
                </ViewMoreWithTags>
              </div>
              <div className="flex flex-wrap justify-between  mt-30">
                <a
                  href="/paid-advertising-services"
                  className="hover-svg font-bolder h-30 w-full md:w-half-auto flex justify-between items-center border-t-1 box-content border-line py-10 text-gray-slider"
                >
                  <span className={'text-black'}>Paid Advertising</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="17" viewBox="0 0 10 17" fill="none">
                    <path d="M1 1L8.5 8.5L1 16" stroke="currentColor" strokeLinecap="round" />
                  </svg>
                </a>
                <a
                  href="/video-production"
                  className="hover-svg font-bolder h-30 w-full md:w-half-auto flex justify-between items-center border-t-1 box-content border-line py-10 text-gray-slider"
                >
                  <span className={'text-black'}>Video Production</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="17" viewBox="0 0 10 17" fill="none">
                    <path d="M1 1L8.5 8.5L1 16" stroke="currentColor" strokeLinecap="round" />
                  </svg>
                </a>
                <a
                  href="/social-media-marketing"
                  className="hover-svg font-bolder h-30 w-full md:w-half-auto flex justify-between items-center border-b-0 border-t-1 md:border-b-1 box-content border-line py-10 text-gray-slider"
                >
                  <span className={'text-black'}>Social Media Marketing</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="17" viewBox="0 0 10 17" fill="none">
                    <path d="M1 1L8.5 8.5L1 16" stroke="currentColor" strokeLinecap="round" />
                  </svg>
                </a>
                <a
                  href="/design"
                  className="hover-svg font-bolder h-30 w-full md:w-half-auto flex justify-between items-center border-t-1 border-b-1 box-content border-line py-10 text-gray-slider"
                >
                  <span className={'text-black'}>Design Services</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="17" viewBox="0 0 10 17" fill="none">
                    <path d="M1 1L8.5 8.5L1 16" stroke="currentColor" strokeLinecap="round" />
                  </svg>
                </a>
                <a
                  href="https://weprodigi.com/main/messenger-chatbots-and-stickers"
                  className="hover-svg font-bolder h-30 w-full md:w-half-auto flex justify-between items-center border-b-0  md:border-b-1 box-content border-line py-10 text-gray-slider"
                >
                  <span className={'text-black'}>Messenger Chatbots</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="17" viewBox="0 0 10 17" fill="none">
                    <path d="M1 1L8.5 8.5L1 16" stroke="currentColor" strokeLinecap="round" />
                  </svg>
                </a>
                <a
                  href="https://weprodigi.com/main/social-media-fun"
                  className="hover-svg font-bolder h-30 w-full md:w-half-auto flex justify-between items-center  border-b-1 box-content border-line py-10 text-gray-slider"
                >
                  <span className={'text-black'}>Branded Game Creation</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="17" viewBox="0 0 10 17" fill="none">
                    <path d="M1 1L8.5 8.5L1 16" stroke="currentColor" strokeLinecap="round" />
                  </svg>
                </a>
              </div>
            </div>
            <div className="w-full md:w-half-auto-2 mt-30 md:mt-0">
              <picture className="">
                <source srcSet="https://weprodigi.com/uploads/images/home-page/our_services.jpg" type="image/png" />
                <img src="https://weprodigi.com/uploads/images/home-page/our_services.jpg" alt="discover image" width="541" height="327" />
              </picture>
            </div>
          </div>
        </div>
      </div>

      <div className={`bg-gray-light`}>
        <div className="container pt-24">
          <div className={'text-black font-bolder text-30 md:text-48 leading-37 md:leading-59'}>TRUSTED BY 120 COMPANIES</div>
          <h2 className={'block md:hidden capitalize text-purple text-24 mt-5  md:text-24 font-normal-700 leading-29  '}>Testimonials</h2>
          <div className="hidden md:flex flex-wrap justify-between mt-25 relative">
            <div className="hidden md:block absolute testimonials">TESTIMONIALS</div>
            <div className="w-half-auto my-shadow rounded-5 py-24 flex flex-col justify-between px-31">
              <div>
                <h2 className={'text-black font-bolder leading-20 text-16'}>The company does everything to save your time</h2>
                <p className={'mt-16 leading-20 text-16'}>
                  The provided consultation helped us increase the results by 40%. The solutions related to Instagram tools and content creativity
                  were the most effective ones. Apart from all this, I would like to highlight that the company does everything to save your time. We
                  felt this approach throughout our cooperation.
                </p>
              </div>
              <div className={'flex justify-between mt-50'}>
                <div className={'flex justify-start w-80p'}>
                  <img src="https://weprodigi.com/uploads/images/home-page/review1.png" width={44} height={44} alt="review1" />
                  <div className={'ml-24'}>
                    <p className={'text-16 font-400 leading-15 text-purple capitalize'}>Picsart</p>
                    <p className={'text-black text-13 font-400'}>CTO, Artavazd Mehrabyan</p>
                  </div>
                </div>
                <a aria-label="view more" href="https://clutch.co/profile/weprodigi-agency?page=1#reviews" target={'_blank'} className={'w-20p'}>
                  <span className={'hidden'}>View More</span>
                  <img src="https://weprodigi.com/uploads/images/home-page/clutch.svg" width={143} height={66} alt="clutch" />
                </a>
              </div>
            </div>
            <div className="w-half-auto my-shadow rounded-5 py-24 flex flex-col justify-between px-31">
              <div>
                <h2 className={'text-black font-bolder leading-20 text-16'}>Their team is incredibly professional and knowledgeable</h2>
                <p className={'mt-16 leading-20 text-16'}>
                  The efforts of Prodigi have been producing the intended results. They continue to communicate effectively, providing constant
                  updates and support. Their strong adherence to quality allows them to meet our high standards.
                </p>
              </div>
              <div className={'flex justify-between mt-50'}>
                <div className={'flex justify-start w-80p'}>
                  <img src="https://weprodigi.com/uploads/images/home-page/review2.png" width={44} height={44} alt="review2" />
                  <div className={'ml-24'}>
                    <p className={'text-16 font-400 leading-15 text-purple capitalize'}>Bentley Yerevan</p>
                    <p className={'text-black text-13 font-400'}>Head, Mariam Matnishyan</p>
                  </div>
                </div>
                <a aria-label="review" href="https://clutch.co/profile/weprodigi-agency?page=1#review-1778692" target={'_blank'} className={'w-20p'}>
                  <span className={'hidden'}>View More</span>
                  <img src="https://weprodigi.com/uploads/images/home-page/clutch.svg" width={143} height={66} alt="clutch" />
                </a>
              </div>
            </div>
            <div className="w-half-auto my-shadow rounded-5 mt-24   py-24  flex flex-col justify-between  px-31">
              <div>
                <h2 className={'text-black font-bolder leading-20 text-16'}>
                  Our company's inbound calls increased four times and 75% were successfully converted into sales
                </h2>
                <p className={'mt-16 leading-20 text-16'}>
                  With WeProdigi Agency’s efforts, Our company's inbound calls increased four times and 75% were successfully converted into sales.
                  The team cooperated effectively, leading to a continued partnership. Overall, they demonstrated a high level of responsiveness,
                  working and accommodating concerns 24/7.
                </p>
              </div>
              <div className={'flex justify-between mt-50'}>
                <div className={'flex justify-start w-80p'}>
                  <img src="https://weprodigi.com/uploads/images/home-page/review3.png" width={44} height={44} alt="review3" />
                  <div className={'ml-24'}>
                    <p className={'text-16 font-400 leading-15 text-purple capitalize'}>ProfConstruct</p>
                    <p className={'text-black text-13 font-400'}>Head of Sales, Simon Denison</p>
                  </div>
                </div>
                <a aria-label="review" href="https://clutch.co/profile/weprodigi-agency?page=3#review-1827276" target={'_blank'} className={'w-20p'}>
                  <span className={'hidden'}>View More</span>
                  <img src="https://weprodigi.com/uploads/images/home-page/clutch.svg" width={143} height={66} alt="clutch" />
                </a>
              </div>
            </div>
            <div className="w-half-auto my-shadow rounded-5 mt-24   py-24  flex flex-col justify-between  px-31">
              <div>
                <h2 className={'text-black font-bolder leading-20 text-16'}>
                  Their clear and thorough communication throughout the project was impressive
                </h2>
                <p className={'mt-16 leading-20 text-16'}>
                  I found most impressive about Prodigi their attention to details, their creativity and passion for the project, their ability to
                  work to tight schedules and meet deadlines, their ability to think outside the box especially during the COVID-19 restrictions .
                </p>
              </div>
              <div className={'flex justify-between mt-50'}>
                <div className={'flex justify-start w-80p'}>
                  <img src="https://weprodigi.com/uploads/images/home-page/review4.png" width={44} height={44} alt="review4" />
                  <div className={'ml-24'}>
                    <p className={'text-16 font-400 leading-15 text-purple capitalize'}>SelectTvFormats LTD</p>
                    <p className={'text-black text-13 font-400'}>Director, Johnson Samuyiwa</p>
                  </div>
                </div>
                <a aria-label="review" href="https://clutch.co/profile/weprodigi-agency#review-1651937" target={'_blank'} className={'w-20p'}>
                  <span className={'hidden'}>View More</span>
                  <img src="https://weprodigi.com/uploads/images/home-page/clutch.svg" width={143} height={66} alt="clutch" />
                </a>
              </div>
            </div>
          </div>
          <div className="block md:hidden relative  mt-10  md:mt-24">
            <Slider {...settings2} className="process-slider relative z-10">
              <div className="rounded-5 max-w-75 shadow-new min-h-320 py-24 flex flex-col justify-between px-10 md:px-31 my-5 md:my-0 mx-10">
                <div>
                  <h2 className={'text-black font-bolder text-16 leading-24'}>The company does everything to save your time</h2>
                  <div className="text-16">
                    <ViewMorePPC ln={20} from="homepage">
                      The provided consultation helped us increase the results by 40%. The solutions related to Instagram tools and content creativity
                      were the most effective ones. Apart from all this, I would like to highlight that the company does everything to save your time.
                      We felt this approach throughout our cooperation.
                    </ViewMorePPC>
                  </div>
                </div>
                <div className={'flex flex-wrap justify-center mt-20'}>
                  <div className={'flex justify-between items-start w-full'}>
                    <div className={'flex items-center'}>
                      <img src="https://weprodigi.com/uploads/images/home-page/review1.png" width={44} height={44} alt="review1" />
                      <div className={'ml-24'}>
                        <p className={'text-purple text-13 font-bolder'}>Picsart</p>
                        <p className={'text-12 font-400 leading-15 text-black '}>CTO, Artavazd Mehrabyan </p>
                      </div>
                    </div>
                    <a
                      aria-label="review"
                      href="https://clutch.co/profile/weprodigi-agency?page=1#review-1771607"
                      target={'_blank'}
                      className={' justify-self-end mt-10'}
                    >
                      <span className={'hidden'}>View More</span>
                      <img src="https://weprodigi.com/uploads/images/home-page/clutch.svg" width={79} height={66} alt="clutch" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="rounded-5 max-w-75 shadow-new min-h-320 py-24 flex flex-col justify-between px-10 md:px-31 my-5 md:my-0 mx-10">
                <div>
                  <h2 className={'text-black font-bolder text-16 leading-24'}>Their team is incredibly professional and knowledgeable</h2>
                  <div className="text-16">
                    <ViewMorePPC ln={16} from="homepage">
                      The efforts of Prodigi have been producing the intended results. They continue to communicate effectively, providing constant
                      updates and support. Their strong adherence to quality allows them to meet our high standards.
                    </ViewMorePPC>
                  </div>
                </div>
                <div className={'flex flex-wrap justify-center mt-20'}>
                  <div className={'flex justify-between items-center w-full'}>
                    <div className={'flex items-center'}>
                      <img src="https://weprodigi.com/uploads/images/home-page/review2.png" width={44} height={44} alt="review2" />
                      <div className={'ml-24'}>
                        <p className={'text-purple text-13 font-bolder'}>Bentley Yerevan</p>
                        <p className={'text-12 font-400 leading-15 text-black '}>Head, Mariam Matnishyan </p>
                      </div>
                    </div>
                    <a
                      aria-label="review"
                      href="https://clutch.co/profile/weprodigi-agency?page=1#review-1778692"
                      target={'_blank'}
                      className={'w-30p'}
                    >
                      <span className={'hidden'}>View More</span>
                      <img src="https://weprodigi.com/uploads/images/home-page/clutch.svg" width={143} height={66} alt="clutch" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="rounded-5 max-w-75 shadow-new min-h-320 py-24 flex flex-col justify-between px-10 md:px-31 my-5 md:my-0 mx-10">
                <div>
                  <h2 className={'text-black font-bolder text-16 leading-24'}>
                    Our company's inbound calls increased four times and 75% were successfully converted into sales
                  </h2>
                  <div className="text-16">
                    <ViewMorePPC ln={12} from="homepage">
                      With WeProdigi Agency’s efforts, Our company's inbound calls increased four times and 75% were successfully converted into
                      sales. The team cooperated effectively, leading to a continued partnership. Overall, they demonstrated a high level of
                      responsiveness, working and accommodating concerns 24/7.
                    </ViewMorePPC>
                  </div>
                </div>
                <div className={'flex flex-wrap justify-center mt-20'}>
                  <div className={'flex justify-between items-center w-full'}>
                    <div className="flex items-center">
                      <img src="https://weprodigi.com/uploads/images/home-page/review3.png" width={44} height={44} alt="review3" />
                      <div className={'ml-24'}>
                        <p className={'text-purple text-13 font-bolder'}>ProfConstruct</p>
                        <p className={'text-12 font-400 leading-15 text-black '}>Head of Sales,Simon Denison </p>
                      </div>
                      <a
                        aria-label={'review'}
                        href="https://clutch.co/profile/weprodigi-agency?page=3#review-1827276"
                        target={'_blank'}
                        className={'w-30p mt-10'}
                      >
                        <img src="https://weprodigi.com/uploads/images/home-page/clutch.svg" width={143} height={66} alt="clutch" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rounded-5 max-w-75 shadow-new py-24 min-h-320 flex flex-col justify-between px-10 md:px-31 my-5 md:my-0 mx-10">
                <div>
                  <h2 className={'text-black font-bolder text-16 leading-24'}>
                    Their clear and thorough communication throughout the project was impressive
                  </h2>
                  <div className={'text-16'}>
                    <ViewMorePPC ln={14} from="homepage">
                      {' '}
                      I found most impressive about Prodigi their attention to details, their creativity and passion for the project, their ability to
                      work to tight schedules and meet deadlines, their ability to think outside the box especially during the COVID-19 restrictions .
                    </ViewMorePPC>
                  </div>
                </div>
                <div className={'flex flex-wrap justify-center mt-20'}>
                  <div className={'flex justify-start w-full'}>
                    <div className="flex items-center">
                      <img src="https://weprodigi.com/uploads/images/home-page/review4.png" width={44} height={44} alt="review4" />
                      <div className={'ml-24'}>
                        <p className={'text-purple text-13 font-bolder'}>SelectTvFormats LTD </p>
                        <p className={'text-12 font-400 leading-15 text-black '}>Director,Johnson Samuyiwa </p>
                      </div>
                      <a
                        aria-label={'review'}
                        href="https://clutch.co/profile/weprodigi-agency#review-1651937"
                        target={'_blank'}
                        className={'w-30p mt-10'}
                      >
                        <img src="https://weprodigi.com/uploads/images/home-page/clutch.svg" width={143} height={66} alt="clutch" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
          <div className="mt-24 flex justify-end items-end">
            <a
              aria-label={'review'}
              href="https://clutch.co/profile/weprodigi-agency#summary"
              target={'_blank'}
              className={' text-purple flex text-20 font-bolder leading-24 hover-arrow'}
            >
              More Reviews
              <svg xmlns="http://www.w3.org/2000/svg" width={25} viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>

      <div className="bg-gray-light">
        <div className="container pb-24 relative ">
          <div className="hidden md:block absolute cases">CASES</div>
          <h2 className={'block md:hidden text-purple text-24 mt-5  md:text-24 font-normal-700 leading-29  '}>Cases</h2>

          {/*<div className={"text-black font-bolder text-30 md:text-48 leading-37 md:leading-59"}>WE CAN BRING RESULTS FOR YOU</div>*/}
          {/*<h2 className={"text-purple text-24 md:text-24 font-normal-700 leading-29 md:leading-44 mt-10"}>Video cases</h2>*/}
          <div className="mt-20">
            <BringResults />
            <div className="mt-24 flex justify-end items-end">
              <a href="https://weprodigi.com/main/cases" className={'text-purple flex text-20 font-bolder hover-arrow leading-24'}>
                More Cases
                <svg xmlns="http://www.w3.org/2000/svg" width={25} viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="container py-24">
          <div className={'text-black font-bolder text-30 md:text-48 leading-37 md:leading-59 mb-5 uppercase'}>we bring results</div>
          <div className="mt-5 md:mt-15 flex flex-wrap justify-between">
            <div className="w-full md:w-half-auto-2 ">
              <h2 className={'text-purple text-24 mt-5  md:text-24 font-normal-700 leading-29  '}>How we create business growth</h2>
              <div className="w-full md:w-half-auto-2 hidden md:hidden">
                <img
                  src="https://weprodigi.com/uploads/images/home-page/grouth4.jpeg"
                  width={540}
                  height={344}
                  alt="grouth4"
                  className={'w-full mt-16'}
                />
              </div>
              <div className="text-left   text-black text-16 leading-24 mt-5 md:mt-16 pl-0 md:pl-0">
                <div className="flex flex-wrap justify-between">
                  <div className="flex items-center w-full   mt-10">
                    <img alt="check" src="https://weprodigi.com/uploads/files/shares/ppc/check.svg" width={15} height={12} />
                    <div className=" ml-15 text-16">Dedicated team who understands your business goals</div>
                  </div>
                  <div className="flex items-center w-full mt-10">
                    <img alt="check" src="https://weprodigi.com/uploads/files/shares/ppc/check.svg" width={15} height={12} />
                    <div className="max-w-none md:max-w-90p ml-15 text-16">Multichannel tracking system which helps track results in real-time </div>
                  </div>
                  <div className="flex items-center w-full mt-10">
                    <img alt="check" src="https://weprodigi.com/uploads/files/shares/ppc/check.svg" width={15} height={12} />
                    <div className="max-w-none md:max-w-90p ml-15 text-16">
                      Certified specialists, who knows every tricks and tools from advertising to creative solutions
                    </div>
                  </div>
                  <div className="flex items-center w-full mt-10">
                    <img alt="check" src="https://weprodigi.com/uploads/files/shares/ppc/check.svg" width={15} height={12} />
                    <div className="max-w-none md:max-w-90p ml-15 text-16">
                      <a href="https://partnersdirectory.withgoogle.com/partners/2472484045" target={'_blank'} className={'text-purple font-medium'}>
                        Google Partner status
                      </a>
                      , which brings benefits like Google’s reward program, exclusive webinars and etc.{' '}
                    </div>
                  </div>
                  <div className="flex items-center w-full mt-10">
                    <img alt="check" src="https://weprodigi.com/uploads/files/shares/ppc/check.svg" width={15} height={12} />
                    <div className="max-w-none md:max-w-90p ml-15 text-16">
                      Clear and concise reporting with 100% transparency and professional analysis
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:w-half-auto-2 block">
              <img
                src="https://weprodigi.com/uploads/images/home-page/grouth4.jpeg"
                width={540}
                height={344}
                alt="grouth4"
                className={'W-full mt-16'}
              />
            </div>
            <div className="mt-20 md:mt-48 flex flex-wrap justify-between">
              <div className="w-full md:w-half-auto-2">
                <h2 className={'text-purple  text-24 md:text-24 font-normal-700 leading-29 '}>The latest digital technologies power our campaigns</h2>
                <img
                  src="https://weprodigi.com/uploads/images/home-page/business-growth3.jpeg"
                  width={540}
                  height={296}
                  alt="business-growth3"
                  className={'W-full mt-24 hidden md:block'}
                />
                <div className={'block md:hidden text-black text-16 leading-24 mt-16'}>
                  <ViewMorePPC ln={35} from="homepage">
                    To provide tangible and immediate results, we are fully armed with cutting-edge digital technologies to evolve into our clients’
                    business goals. Aside from world-class technologies, we also utilize owned technologies to create more value for our clients.
                    Those technologies are used across all digital marketing processes and channels, from SMM to production and more. Here are some of
                    the most significant technologies implemented into our services AI chatbots Augmented Reality for Social Media Marketing; Smart
                    Bidding, Performance Max, GA4, Adobe Analytics, and Amplitude for PPC marketing; Interactive videos and designs, and AI for
                    production. Nailing the latest technologies and keeping the pace of innovations, we are ready to empower your campaigns with the
                    latest technologies and our expertise.
                  </ViewMorePPC>
                </div>
              </div>
              <div className="w-full md:w-half-auto-2">
                <p className={'hidden md:block text-black text-16 leading-24'}>
                  To provide tangible and immediate results, we are fully armed with cutting-edge digital technologies to evolve into our clients’
                  business goals. Aside from world-class technologies, we also utilize owned technologies to create more value for our clients. Those
                  technologies are used across all digital marketing processes and channels, from SMM to production and more. Here are some of the
                  most significant technologies implemented into our services AI chatbots Augmented Reality for Social Media Marketing; Smart Bidding,
                  Performance Max, GA4, Adobe Analytics, and Amplitude for PPC marketing; Interactive videos and designs, and AI for production.
                  Nailing the latest technologies and keeping the pace of innovations, we are ready to empower your campaigns with the latest
                  technologies and our expertise.
                </p>

                <img
                  src="https://weprodigi.com/uploads/images/home-page/business-growth3.jpeg"
                  width={540}
                  height={296}
                  alt="business-growth3.png"
                  className={'W-full mt-24 block md:hidden'}
                />

                <div className="mt-10 md:mt-20">
                  {/*<button className={"w-full  h-38 md:h-64 bg-purple border-1 rounded-5 border-purple text-white text-20 font-bolder leading-24"}>Meet WeProdigi</button>*/}
                  <button
                    onClick={() => {
                      if (props.width < 768) {
                        window.location.href = '/proposals/get-a-proposal';
                      } else {
                        setShowModal(true);
                        setHidden();
                      }
                    }}
                    className={
                      'w-full mt-10 hover:bg-purple hover:text-white h-38 md:h-64 bg-transparent border-1 rounded-5 border-purple text-purple text-18 md:text-20 font-normal md:font-bolder leading-20 md:leading-24'
                    }
                  >
                    Get result-oriented proposal now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`bg-gray-light`}>
        <div className={`image-left-block py-20 md:py-30 container`}>
          <h1 className={'text-black text-30 md:text-48 leading-37 md:leading-59 font-bolder uppercase'}>certified by</h1>
          <div className="mt-0 md:mt-15 flex flex-wrap justify-between md:justify-between items-start">
            <div className="max-w-100p  md:max-w-250 w-full mt-15 flex md:block  items-center">
              <a className={'w-150 md:w-auto'} href="https://www.google.com/partners/agency?id=2472484045" target={'_blank'}>
                <img className="mb-17 mr-5" alt="partner" src="https://weprodigi.com/uploads/images/home-page/partner1.svg" width={80} />
              </a>
              <ViewMorePPC ln={15} from="ppc">
                This certificate badge shows that we are Google’s official partners, we have appropriate benefits like their reward program, exclusive
                webinars, etc.
              </ViewMorePPC>
            </div>
            <div className="max-w-100p  md:max-w-250 w-full mt-20 md:mt-15 flex md:block  items-center">
              <a className={'w-150 md:w-auto'} href="https://www.credly.com/badges/5a49bdca-665c-4087-9428-37cad0ec1938/public_url" target={'_blank'}>
                <img alt="partner" className="mb-23 mr-5" src="https://weprodigi.com/uploads/files/shares/ppc/blueprint.svg" width={80} />
              </a>
              <ViewMorePPC ln={16} from="ppc">
                This credential is awarded to our digital marketers who have proficiency in the skills, tools, advertising policies, and best
                practices required to buy ads on the Facebook App, Instagram, and Messenger.
              </ViewMorePPC>
            </div>
            <div className="max-w-100p  md:max-w-250 w-full mt-20 md:mt-15 flex md:block  items-center  ">
              <a className={'w-150 md:w-auto'} href="https://weprodigi.com/uploads/files/shares/certificates/Course_Certificate.pdf">
                <img alt="partner" className="mb-16 mr-5" src="https://weprodigi.com/uploads/files/shares/ppc/analitics.svg" width={80} />
              </a>
              <ViewMorePPC ln={16} from="ppc">
                This certificate shows that our team is fluent in analytics and processing data to gain key business insights.
              </ViewMorePPC>
            </div>
            <div className="max-w-100p  md:max-w-250 w-full mt-20 md:mt-15 flex md:block  items-center ">
              <a className={'w-150 md:w-auto'} href="#">
                <img alt="partner" className="mb-25 mr-5" src="https://weprodigi.com/uploads/files/shares/ppc/bing.svg" width={80} />
              </a>
              <ViewMorePPC ln={16} from="ppc">
                This badge shows that we’re pros in the advertising platform to help you maximize budget and results when running ads on the Microsoft
                network.
              </ViewMorePPC>
            </div>
            <div className="max-w-100p  md:max-w-250 w-full mt-20 md:mt-15 flex md:block  items-center">
              <a className={'w-150 md:w-auto'} href="https://weprodigi.com/uploads/files/shares/certificates/hubspot.png?">
                <img alt="partner" className="mb-15 mr-5" src="https://weprodigi.com/uploads/files/shares/ppc/hubspot.svg" width={80} />
              </a>
              <ViewMorePPC ln={11} from="ppc">
                This badge includes certificates for Email Marketing, Inbound Sales, Social Media, Inbound Marketing, and Content Marketing which will
                help us reach your target audience, strengthen the customer relationship, and help your business grow.
              </ViewMorePPC>
            </div>
            <div className="max-w-100p  md:max-w-250 w-full mt-20 md:mt-15 flex md:block  items-center">
              <a className={'w-150 md:w-auto'} href="https://weprodigi.com/uploads/files/shares/certificates/hootsuite.jpg">
                <img alt="partner" className="mb-22 mr-5" src="https://weprodigi.com/uploads/files/shares/ppc/g833.svg" width={80} />
              </a>
              <ViewMorePPC ln={13} from="ppc">
                This certificate demonstrates our knowledge of the core principles and strategy behind social media marketing, boosting the impact of
                your social media efforts, driving results, and proving your ROI.
              </ViewMorePPC>
            </div>
            <div className="max-w-100p  md:max-w-250 w-full mt-20 md:mt-15 flex md:block  items-center">
              <a className={'w-150 md:w-auto'} href="https://weprodigi.com/uploads/files/shares/certificates/oglivy.jpg">
                <img alt="partner" className="mb-8 mr-5" src="https://weprodigi.com/uploads/files/shares/ppc/oglivy.svg" width={80} />
              </a>
              <ViewMorePPC ln={15} from="ppc">
                This badge shows that we’re experts in growth & innovation, advertising, we experience work fluidly, problems and obstacles are
                obvious to us.
              </ViewMorePPC>
            </div>
            <div className="max-w-100p  md:max-w-250 w-full mt-20 md:mt-15 flex md:block  items-center">
              <a className={'w-150 md:w-auto'} href="https://weprodigi.com/uploads/files/shares/certificates/vanes-lion-lilit.jpg">
                <img alt="partner" className="mb-5 " src="https://weprodigi.com/uploads/files/shares/ppc/lions.svg" width={80} />
              </a>
              <ViewMorePPC ln={12}>
                This puts us among the global creative staff, from research to objectives, KPIs, customer journeys, briefings, evaluations, etc.
              </ViewMorePPC>
            </div>
          </div>
        </div>
      </div>
      <style>{`
                         .bg-gradient{
                          background:url(https://weprodigi.com/uploads/images/home-page/team_back3.png);
                          background-size:cover
                        }
                        
                    `}</style>
      <div className="bg-gradient">
        <div className="container py-24">
          <div className={'text-black font-bolder text-30 md:text-48 leading-37 md:leading-59 uppercase'}>Meet Your future team</div>
          <div className="mt-5 md:mt-24 flex flex-wrap justify-between md:justify-between items-start">
            <div className="w-auto mx-auto md:w-half-auto-2">
              <div className="w-full flex items-end">
                <div className={'relative mr-12 md:mr-40'}>
                  <img
                    src="https://weprodigi.com/uploads/images/home-page/valera.png"
                    alt="valera"
                    className={'w-160 md:w-213'}
                    width={213}
                    height={247}
                  />
                  <a
                    aria-label={'link'}
                    href="https://www.linkedin.com/in/valeri-akhverdian-93b6601a6/"
                    target={'_blank'}
                    className={'absolute  right-15 top-8 scale-on-hover'}
                  >
                    {' '}
                    <img
                      src="https://weprodigi.com/uploads/images/home-page/LN.svg"
                      className={'w-33px md:w-48'}
                      alt="LN"
                      width={48}
                      height={32.49}
                    />
                  </a>
                </div>
                <div className={'relative mt-24  '}>
                  <img
                    src="https://weprodigi.com/uploads/images/home-page/anahit.png"
                    alt="anahit"
                    className={'w-144 md:w-177'}
                    width={177}
                    height={205}
                  />
                  <a
                    aria-label={'link'}
                    href="https://www.linkedin.com/in/anahit-karapetian-091773111"
                    target={'_blank'}
                    className={'absolute right-10 top-8 scale-on-hover'}
                  >
                    {' '}
                    <img
                      src="https://weprodigi.com/uploads/images/home-page/LN.svg"
                      className={'w-33px md:w-48'}
                      alt="LN"
                      width={48}
                      height={32.49}
                    />
                  </a>
                </div>
              </div>
              <div className="w-full flex items-start justify-start mt-12 md:mt-24">
                <div className={'relative ml-15 md:ml-35 mr-12 md:mr-40'}>
                  <img
                    src="https://weprodigi.com/uploads/images/home-page/sirik.png"
                    alt="sirik"
                    className={'w-144 md:w-177'}
                    width={177}
                    height={205}
                  />
                  <a
                    aria-label={'link'}
                    href="https://www.linkedin.com/in/siranush-tovmasyan/"
                    target={'_blank'}
                    className={'absolute right-10 top-8 scale-on-hover'}
                  >
                    {' '}
                    <img
                      src="https://weprodigi.com/uploads/images/home-page/LN.svg"
                      alt="LN"
                      className={'w-33px md:w-48'}
                      width={48}
                      height={32.49}
                    />
                  </a>
                </div>
                <div className={'relative '}>
                  <img
                    src="https://weprodigi.com/uploads/images/home-page/tiko.png"
                    alt="tiko"
                    className={'w-160 md:w-213'}
                    width={213}
                    height={247}
                  />
                  <a
                    aria-label={'link'}
                    href="https://www.linkedin.com/in/tigran-bazikyan-18583a22b"
                    target={'_blank'}
                    className={'absolute right-10 md:right-15 top-8 scale-on-hover'}
                  >
                    {' '}
                    <img
                      src="https://weprodigi.com/uploads/images/home-page/LN.svg"
                      alt="LN"
                      className={'w-33px md:w-48'}
                      width={48}
                      height={32.49}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="w-full md:w-half-auto-2">
              <p className={'mt-10 md:mt-0 text-black text-16 leading-24'}>
                Every great project is the result of a team that puts ideas into action, shapes them, and gives them words. And every great team is
                led by a direction that energizes, orients, and engages its members. With the commitment as the cornerstone of the teamwork, we have
                gathered and nurtured true professionals in their specialties delivering exceptional quality and results with teamwork in each of our
                projects. Our corporate culture is built on mutual trust, respect, accountability, and high-level communication. At Prodigi, each
                service is supported by a complete team of in-house professionals, covering all the current services. It means, regardless of your
                business industry, stage of business growth, or requirements, we are capable of addressing your needs with an action plan. With our
                team of digital marketing professionals, social media specialists, designers, and production specialists, we are able to cover the
                full-cycle digital marketing services and provide real measurable results.
              </p>
              <div className="mt-20">
                <div className={'w-full flex'}>
                  <a
                    href={'https://weprodigi.com/team'}
                    className="text-center capitalize hover:bg-green  block w-full md:w-auto  text-white bg-purple rounded-5 px-5 md:px-23 py-5"
                  >
                    Meet WeProdigi
                  </a>

                  <a
                    className="  ml-20 hover:bg-purple w-full md:w-auto text-white bg-green-badge rounded-5 px-5 text-center md:px-23  flex items-center justify-center "
                    href={'https://calendly.com/info-weprodigi/call-with-account-team-digital-marketing'}
                  >
                    Schedule a Call
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-contact h-425 md:h-310 overflow-hidden relative">
        <style>{`
                            .bg-contact{
                              background:url(https://weprodigi.com/uploads/images/home-page/contact.jpeg);
                              background-size:cover;
                              background-position: 0;
                            }
                              @media screen  and (max-width:1024px){
                                  
                                 .bg-contact{
                                   background-size: cover;
                                   background-position: 0px 1px!important;
                                 }
                               }
                    `}</style>
        <div id="stars" />
        <div id="stars2" />
        <div id="stars3" />
        <div className="container flex flex-col justify-evenly items-center h-full ">
          <div className={'text-center   text-white leading-37 md:leading-59 text-30 md:text-48'}>
            And if you are already here this is one more proof that we’re digital marketing heroes, so... <br />
            <div className={'flex justify-center mt-0'}>
              <button
                className=" h-40 text-18 leading-24  hover:bg-purple md:flex justify-center items-center w-full md:w-auto mt-24 text-white bg-green-badge rounded-5 px-23 py-0 md:py-5"
                onClick={() => {
                  if (window.innerWidth < 768) {
                    window.location.href = '/get-proposal?t=contact';
                  } else {
                    setHidden();
                    setShowModalPPC(true);
                  }
                }}
              >
                Contact us
              </button>
            </div>
            <div className={'cursor-pointer'}>
              <a
                href={'/proposals/voice-proposal'}
                className=" flex md:hidden items-center justify-center mt-20 border rounded-5 border-white text-center text-18 text-white px-23 py-2 hover:bg-white  hover:text-green "
              >
                <svg width="15" height="23" viewBox="0 0 15 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_13026_9392)">
                    <path
                      d="M8.22446 0C8.70859 0.177107 9.22993 0.29051 9.67051 0.542165C11.156 1.39336 11.9042 2.71399 11.9179 4.41322C11.9383 7.03007 11.9315 9.64738 11.9206 12.2642C11.912 14.4089 10.563 16.1005 8.4722 16.6228C6.27113 17.1731 3.82369 15.7391 3.26197 13.5505C3.14958 13.1152 3.09032 12.668 3.08546 12.2186C3.06913 9.63156 3.06051 7.04453 3.08274 4.45795C3.09908 2.55812 3.98114 1.16837 5.70941 0.346534C6.04381 0.187047 6.42041 0.114758 6.7775 0.00225902L8.22446 0Z"
                      fill="currentColor"
                    />
                    <path
                      d="M6.80159 21.6234V19.789C6.44405 19.7208 6.09376 19.6743 5.75437 19.5853C2.99476 18.8619 1.18527 17.1288 0.306383 14.4324C0.0908588 13.7733 0.0110016 13.087 0.00011198 12.3939C-0.0062403 11.9846 0.258287 11.6805 0.63534 11.6557C1.03236 11.6294 1.31866 11.8852 1.37629 12.3085C1.45161 12.8633 1.47656 13.4326 1.62221 13.9693C2.07867 15.6464 3.1032 16.9119 4.64317 17.713C6.47445 18.6658 8.3638 18.7038 10.2168 17.7808C12.1166 16.8347 13.221 15.2741 13.555 13.1827C13.6058 12.8665 13.6003 12.5403 13.6457 12.2213C13.6675 12.0636 13.746 11.9192 13.8667 11.8148C13.9873 11.7104 14.142 11.6532 14.3018 11.6538C14.3944 11.6509 14.4865 11.6668 14.5727 11.7006C14.6588 11.7344 14.7372 11.7853 14.8029 11.8503C14.8686 11.9153 14.9203 11.9929 14.9548 12.0784C14.9894 12.164 15.006 12.2556 15.0037 12.3478C14.9915 13.5677 14.7224 14.727 14.1416 15.8028C13.1534 17.6317 11.657 18.8624 9.66647 19.4804C9.19594 19.6264 8.70228 19.6969 8.20363 19.8057V21.6066C8.28348 21.6121 8.36198 21.6225 8.44093 21.6225C9.16373 21.6225 9.88698 21.6225 10.6098 21.6256C10.7448 21.6225 10.8795 21.6409 11.0086 21.6803C11.3085 21.7837 11.4814 22.0996 11.4356 22.4181C11.4117 22.568 11.3384 22.7058 11.2273 22.8098C11.1162 22.9137 10.9735 22.978 10.8217 22.9923C10.7023 23.0041 10.5807 22.9986 10.4587 22.9986C8.41779 22.9986 6.37689 22.9986 4.336 22.9986C3.84642 22.9986 3.53924 22.7023 3.56465 22.2622C3.58597 21.8836 3.87909 21.6297 4.32375 21.6256C5.05426 21.6193 5.78477 21.6256 6.51528 21.6256L6.80159 21.6234Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_13026_9392">
                      <rect width="15" height="23" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span className={'ml-10'}>Send a Voice Message</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="proposals">
        <Offer country={props.country} id={'form-offer'} show={showOfferModal} hide={hideOfferModal} title={''} offerPrice={0} offerDuration={0} />
        <GetProposal
          from={'contact'}
          country={props.country}
          show={showModalPPC}
          id={'form-ppc'}
          hide={hideOfferModalPPC}
          website={''}
          currency={props.currency}
        />
        <SMMPricing step={1} country={props.country} show={showModalSMM} title="Get SMM Pricing" hide={hideOfferModaSMM} />
        <Pricing videoLength={''} script={''} id country={props.country} show={showPricingModal} hide={hideOfferModaPricing} step={1} />

        <GetProposal
          country={props.country}
          show={showOfferModalWeb}
          id={'form-web'}
          hide={hideOfferModalWeb}
          website={''}
          currency={props.currency}
          title={'Web Development'}
        />

        <GetProposal
          country={props.country}
          show={showOfferModalDesign}
          id={'form-web'}
          hide={hideOfferModalDesign}
          website={''}
          currency={props.currency}
          title={'Design Services '}
        />
      </div>
    </>
  );
};
export default HomePage;
