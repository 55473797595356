import React from 'react';

class ViewMore extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
    this.click = this.click.bind(this);
  }

  click() {
    this.setState({ show: !this.state.show });
  }
  componentToString(txt) {
    var text = '';

    for (let i = 0; i < txt.length; i++) {
      if (typeof txt[i] === 'string') {
        text += txt[i];
      }
    }
    return text;
  }

  render() {
    return (
      <>
        {this.state.show ? (
          <div className={`${this.props.from == 'homepage' ? 'ml-0' : 'ml-10'} md:ml-0`}>
            {this.props.children}{' '}
            <span onClick={this.click} className="text-purple cursor-pointer underline-anim">
              {' '}
              <span>{this.state.show ? 'view less' : 'view more'}</span>
              <svg viewBox="0 0 13 20">
                <polyline points="0.5 19.5 3 19.5 12.5 10 3 0.5" />
              </svg>
            </span>
          </div>
        ) : (
          <div className={`${this.props.from == 'homepage' ? 'ml-0' : 'ml-10'} md:ml-0`}>
            {this.componentToString(this.props.children)
              .split(' ')
              .splice(0, this.props.ln || 19)
              .join(' ')}{' '}
            <span onClick={this.click} className="text-purple cursor-pointer underline-anim">
              {' '}
              <span>{this.state.show ? 'view less' : 'view more'}</span>
              <svg viewBox="0 0 13 20">
                <polyline points="0.5 19.5 3 19.5 12.5 10 3 0.5" />
              </svg>
            </span>
          </div>
        )}{' '}
      </>
    );
  }
}

export default ViewMore;
